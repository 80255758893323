import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const loginOtpInitiation = createAsyncThunk(
  "shopperInfo/login",
  async ({ mobileNumber, keepSignedIn, checkoutToken }, { rejectWithValue }) => {
    try {
      const requestData = {
        shopperRef: mobileNumber,
        refType: "Mobile",
        checkoutToken
      };
      if (keepSignedIn) {
        requestData.extendedLogin = true;
      }

      const response = await axios.post(
        `${baseUrl}/checkout/sessions/${checkoutToken}/shoppers/login/initiate`,
        requestData
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err?.response);
    }
  }
);

export const loginOtpVerification = createAsyncThunk(
  "shopperInfo/login/otp",
  async ({ mobileNumber, otp, checkoutToken }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`${baseUrl}/checkout/sessions/${checkoutToken}/shoppers/login`, {
        shopperRef: mobileNumber,
        refType: "Mobile",
        token: otp,
      });
      return response.data;
    } catch (err) {
      return rejectWithValue(err?.response);
    }
  }
);

const initialState = {
  shopperinfo: {
    shopperRef: localStorage.getItem("shopperRef"),
    sessionToken: localStorage.getItem("sessionToken"),
  },
  showOtpModal: false,
  otpInitiated: false,
  otpVerificationInitiated: false,
  loginOtpInitiationErrorMsg: "",
  loginOtpVerificationErrMsg: "",
  isLoggedIn: localStorage.getItem("sessionToken") ? true : false,
  keepSignedIn: false,
  guestCheckout: sessionStorage.getItem("sessionTokenForGuest") ? true : false,
  isGuestLogedIn: sessionStorage.getItem("isLogedInForGuest") ? true : false,
};

const shopperInfoSlice = createSlice({
  name: "shopperInfo",
  initialState,
  reducers: {
    resetOtpModal(state) {
      state.showOtpModal = false;
    },
    resetOtpInitiationError(state) {
      state.loginOtpInitiationErrorMsg = "";
    },
    resetOtpValidationError(state) {
      state.loginOtpVerificationErrMsg = "";
    },
    resetShopperInfo(state) {
      state.isLoggedIn = false;
      state.guestCheckout = false;
      state.shopperinfo = {};
      localStorage.removeItem("sessionToken");
      localStorage.removeItem("shopperRef");
      sessionStorage.removeItem("latestSavedAddress");
      sessionStorage.removeItem("sessionTokenForGuest");
      sessionStorage.removeItem("paymentMethods");
      sessionStorage.removeItem("isLogedInForGuest");
    },
    handleKeepSignIn: (state, action) => {
      state.keepSignedIn = action.payload;
    },
    handleGuestCheckout: (state, action) => {
      state.guestCheckout = action.payload;
      sessionStorage.setItem("sessionTokenForGuest", state.guestCheckout);
    },
    handleGuestLogedIn: (state, action) => {
      state.isGuestLogedIn = action.payload;
      sessionStorage.setItem("isLogedInForGuest", state.isGuestLogedIn);
    },
  },
  extraReducers: {
    [loginOtpInitiation.pending]: (state) => {
      state.otpInitiated = true;
      state.loginOtpVerificationErrMsg = "";
    },
    [loginOtpInitiation.fulfilled]: (state, action) => {
      state.showOtpModal = true;
      state.otpInitiated = false;
    },
    [loginOtpInitiation.rejected]: (state, action) => {
      state.otpInitiated = false;
      state.showOtpModal = false;
      state.loginOtpInitiationErrorMsg = action.payload?.data?.message;
    },

    [loginOtpVerification.pending]: (state) => {
      state.otpVerificationInitiated = true;
      state.loginOtpInitiationErrorMsg = "";
    },
    [loginOtpVerification.fulfilled]: (state, action) => {
      state.otpVerificationInitiated = false;
      state.loginOtpVerificationErrMsg = "";
      state.shopperinfo = action.payload;
      state.isLoggedIn = true;
      state.showOtpModal = false;
      localStorage.setItem("sessionToken", action?.payload?.sessionToken);
      localStorage.setItem("shopperRef", action?.payload?.shopperRef);
    },
    [loginOtpVerification.rejected]: (state, action) => {
      state.otpVerificationInitiated = false;
      if (action?.payload?.status === 429) {
        state.showOtpModal = false;
        state.loginOtpInitiationErrorMsg = action.payload?.data?.message;
      } else {
        state.loginOtpVerificationErrMsg = action.payload?.data?.message;
      }
    },
  },
});

export const selectMobileNumber = (state) =>
  state?.shopperInfo?.shopperinfo?.shopperRef;

export const selectSessionToken = (state) =>
  state?.shopperInfo?.shopperinfo?.sessionToken;

export const selectGuestCheckout = (state) => state?.shopperInfo?.guestCheckout;

export const {
  resetOtpModal,
  resetOtpInitiationError,
  resetOtpValidationError,
  resetShopperInfo,
  handleKeepSignIn,
  handleGuestCheckout,
  handleGuestLogedIn,
} = shopperInfoSlice.actions;
export default shopperInfoSlice.reducer;
