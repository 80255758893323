import React, { useEffect, useCallback } from "react";
import { Form, Input, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { selectIsMobileLayout } from "../../../../slices/MobileLayoutSlice";
import "./upi.css";
import {
  changeActivePaymentMethod,
  changePaymentMethodInfo,
  selectActivePaymentMethod,
  selectButtonError,
  selectPaymentMethodInfo,
  toggleButtonError,
} from "../../../../slices/PaymentButtonSlice";
import {
  changeUpiVpa,
  handleUpiError,
  selectUpiVpa,
  vpaError,
} from "../../../../slices/UpiSlice";
import PyamentButton from "../../paymentButton/PaymentButton";
import {
  instrumentProvidedEvent,
  methodSelectedEvent,
} from "../../../../Utils/uiAnalytics";
import { handleTotalPaymentSummaryError } from "../../../../slices/HandleAdditionalComponentsSlice";
import { removeSelectedIntruments } from "../../../../slices/SavedInstrumentSlice";
import { isEmpty } from "../../../../Utils/utils";
import TranslateText from "../../../resuableComponents/TranslateText";
import { useTranslation } from "react-i18next";
import { useRef } from "react";

const UpiVpa = ({ upiData }) => {
  const [form] = Form.useForm();
  const { t, i18n } = useTranslation();
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));
  const buttonError = useSelector((state) => selectButtonError(state));
  const upiVpaError = useSelector((state) => vpaError(state));
  const activePaymentMethod = useSelector((state) => selectActivePaymentMethod(state));
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);
  const paymentMethodInfo = useSelector((state) => selectPaymentMethodInfo(state));
  const upiValueRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!buttonError) {
      dispatch(handleUpiError(""));
    }
  }, [buttonError]);

  const handleUpiChange = (e) => {
    let value = e.target.value;
    const regexForAtTheRate = /.+@..+/;
    if (isEmpty(value)) {
      dispatch(handleUpiError("")); dispatch(toggleButtonError(true));
    }
    upiValueRef.current = value;
    if (!regexForAtTheRate.test(value)) {
      dispatch(toggleButtonError(true));
      return
    }

    const regex = /^[a-zA-Z\d.-]{2,256}@[a-zA-Z][a-zA-Z]{2,64}$/; //UPI regex
    dispatch(handleTotalPaymentSummaryError(""));
    dispatch(removeSelectedIntruments());
    if (paymentMethodInfo?.brand != "UpiCollect") {
      dispatch(changePaymentMethodInfo(upiData));
    }

    if (value && regex.test(value)) {
      dispatch(toggleButtonError(false));
      dispatch(handleUpiError(""));
    } else {
      dispatch(toggleButtonError(true));
      dispatch(handleUpiError(t("ENTER_VALID_UPI")));
    }
    dispatch(changeUpiVpa(value));
    methodSelectedEvent(sessionDetails, "Upi", "UpiCollect", dispatch);
    instrumentProvidedEvent(sessionDetails, "Upi", "UpiCollect", dispatch);
  };

  useEffect(() => {
    const handleLanguageChange = () => {
      if (!isEmpty(upiVpaError)) {
        dispatch(handleUpiError(t("ENTER_VALID_UPI")));
      }
    };
    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n, upiValueRef.current, upiVpaError]);

  return (
    <>
      <Form
        form={form}
        name="validateOnly"
        layout="vertical"
        autoComplete="off"
      >
        <Form.Item
          name="name"
          style={{ marginBottom: isMobileLayout ? "0px" : "" }}
          label={
            !isMobileLayout && (
              <Typography className="upi-vpa-label dynamic-font-family">
                <TranslateText label={"PAY_VIA_UPI"}>Pay via UPI ID</TranslateText>
              </Typography>
            )
          }
        >
          <Input
            onFocus={() => {
              if (activePaymentMethod !== "Upi") {
                dispatch(changeActivePaymentMethod("Upi"));
              }
            }}
            onChange={handleUpiChange}
            placeholder={t("ENTER_UPI_ID")}
            style={{ borderColor: upiVpaError ? "#ff4d4f" : "" }}
          />
          <Typography
            className="regular12Heading"
            style={{ color: "var(--Red-800)" }}
          >
            {upiVpaError}
          </Typography>
        </Form.Item>
        <Form.Item style={{ display: isMobileLayout ? "none" : "" }}>
          <PyamentButton />
        </Form.Item>
      </Form>
    </>
  );
};

export default UpiVpa;
