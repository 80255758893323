import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  SelectMerchantDetails,
  selectFrontendBackUrl,
  selectHeaderTextColor,
} from "../../../slices/PaymentSessionDetailsSlice";
import { generateDynamicLogo, isEmpty } from "../../../Utils/utils";
import "./navBarCheckout.css";
import { Typography, Select } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import ErrorModal from "../../components/modals/errorModal/ErrorModal";
import { useTranslation } from "react-i18next";
import { setSelectedLanguage } from "../../../slices/CommonPersistedSlice";

const Navbar = () => {
  const headerTextColor = useSelector((state) => selectHeaderTextColor(state));
  const merchantDetails = useSelector((state) => SelectMerchantDetails(state));
  const frontendBackUrl = useSelector((state) => selectFrontendBackUrl(state));
  const [open, setOpen] = useState(false);
  const callerType = useSelector((state) => state.paymentSessionDetails.callerType);
  const isMerchantLogo = useSelector((state) => state.additionalComponents.isMerchantLogo);
  const sampleToken = useSelector((state) => state.paymentSessionDetails.sampleToken);
  const selectedLanguage = useSelector((state) => state.commonPersisted.selectedLanguage);
  const isIframe = window.self !== window.top;
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  // const frontendBackUrl = sessionDetails?.paymentDetails?.frontendBackUrl;

  const handleChange = (value) => {
    dispatch(setSelectedLanguage(value))
    i18n.changeLanguage(value);
  };
  const handleConfirmClick = () => {
    if (isIframe) {
      const message = {
        action: 'paymentCancelled',
      };
      window.parent.postMessage(message, '*');
    } else {
      window.location.href = frontendBackUrl;
    }
  }
  return (
    <div className="domestic-navbar-root dynamic-header">
      <ErrorModal
        icon={<CloseCircleOutlined style={{ color: "#FAAD14", fontSize: "22px" }} />}
        title={`${t("CANCEL_ORDER_CONFIRMATION")}?`}
        desc={t("PROGRESS_LOST_ORDER_NOT_COMPLETED")}
        showModal={open}
        primaryOnClick={handleConfirmClick}
        secondaryOnClick={() => setOpen(false)}
        primaryBtnText={"Yes, cancel"}
        secondaryBtnText={"No"}
        showSecondaryButton={true}
      />
      <div className="nav-header-checkout">
        <div className="nav-header-container">

          {(callerType === "CHECKOUT" || (callerType === 'PAYMENT_LINK' && frontendBackUrl)) && !sampleToken && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
              onClick={() => setOpen(true)}
            >
              {generateDynamicLogo(
                "leftArrow",
                "24px",
                "24px",
                "",
                headerTextColor
              )}
            </div>
          )}
          {isMerchantLogo && merchantDetails?.logoUrl && (
            <img className="nav-logo-checkout" src={merchantDetails?.logoUrl} />
          )}
          <Typography
            style={{
              color: headerTextColor,
              fontWeight: '600',
              fontSize: '16px'
            }}
          >
            {merchantDetails?.merchantName}{" "}
          </Typography>
        </div>
        <div className="nav-select-box">
          <Select
            defaultValue="en"
            value={isEmpty(selectedLanguage) ? 'en' : selectedLanguage}
            style={{
              width: 120,
            }}
            onChange={handleChange}
            options={[
              {
                value: 'en',
                label: t('ENGLISH'),
              },
              {
                value: 'zh',
                label: t('MANDARIN'),
              },
              {
                value: 'vi',
                label: t('VIETNAMESE'),
              },
              {
                value: 'id',
                label: t('BAHASA'),
              },
              {
                value: 'ko',
                label: t('KOREAN'),
              },
            ]}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
