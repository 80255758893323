import React, { useEffect } from "react";
import { Collapse, Typography } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import TranslateText from "../../resuableComponents/TranslateText";
import "../fontStyling.css";

const OrderSummaryMobileContainer = ({ children }) => {
  const { t } = useTranslation();
  const items = useSelector(
    (state) =>
      state.paymentSessionDetails.sessionDetails?.paymentDetails?.order?.items
  );

  const number_of_items = items?.reduce((acc, val) => {
    acc += val.quantity;
    return acc;
  }, 0);

  const getExtra = () => {
    return (
      <>
        {number_of_items && (
          <Typography
            className="semiBold12Heading dynamic-font-family"
            style={{ color: "var(--Neutral-Grey-1000)" }}
          >
            {`${number_of_items} ${number_of_items > 1 ? t("ITEMS") : t("ITEM")}`}
          </Typography>
        )}
      </>
    );
  };
  return (
    <Collapse
      expandIconPosition="end"
      defaultActiveKey={["paymentSummary"]}
      items={[
        {
          key: "paymentSummary",
          label: (
            <Typography className="domestic-ps-mobile-header dynamic-font-family">
              <TranslateText label={"ORDER_SUMMARY"}>Order Summary</TranslateText>
            </Typography>
          ),
          children: <div className="dynamic-font-family">{children}</div>,
          extra: getExtra(),
        },
      ]}
    />
  );
};

export default OrderSummaryMobileContainer;
