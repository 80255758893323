import { Typography } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { selectSelectedIntrument } from "../../../../slices/SavedInstrumentSlice";
import { selectCurrencyCode, selectCurrencySymbol, } from "../../../../slices/PaymentSessionDetailsSlice";
import getSymbolFromCurrency from "currency-symbol-map";
import TranslateText from "../../../resuableComponents/TranslateText";

export default function EmiPlanDetails({ paymentInfo }) {
  const { t } = useTranslation();
  const selectedInstrument = useSelector((state) => selectSelectedIntrument(state));
  const currencyCode = useSelector((state) => selectCurrencyCode(state));
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);

  const AmountComponent = ({ amount, styles }) => {
    return <span style={{ ...styles }}>
      <span
        style={{
          fontFamily: "sans-serif",
        }}
      >
        {getSymbolFromCurrency(currencyCode)}{" "}
      </span>
      {amount}
    </span>
  }

  return (
    <>
      <div>
        <div style={{ display: "flex" }}>
          <Typography className="emiDetailsHeading dynamic-font-family">
            <TranslateText label={'TOTAL_COST'}>Total Cost</TranslateText>
          </Typography>
          <Typography className="emiDetailsHeading emiDetailsMargin dynamic-font-family">
            <AmountComponent amount={selectedInstrument?.emiMethod?.totalAmount} />
          </Typography>
        </div>
        <div className="detailsStyling">
          <Typography className="emiDetailsHeading dynamic-font-family">
            <TranslateText label={'MONTHLY_EMI'}>Monthly EMI</TranslateText>
          </Typography>
          <Typography className="emiDetailsHeading emiDetailsMargin dynamic-font-family">
            <AmountComponent amount={selectedInstrument?.emiMethod?.emiAmountLocaleFull} />
          </Typography>
        </div>
        <div className="detailsStyling">
          <Typography className="emiDetailsHeading dynamic-font-family">
            <TranslateText label={'TOTAL_INTEREST_CHARGED_BY_BANK'}>Total Interest (Charged by Bank)</TranslateText>
          </Typography>
          <Typography className="emiDetailsHeading emiDetailsMargin dynamic-font-family">
            <AmountComponent amount={selectedInstrument?.emiMethod?.interestChargedAmount} />
          </Typography>
        </div>
        {selectedInstrument?.emiMethod?.processingFee && (
          <div className="detailsStyling">
            <Typography className="emiDetailsHeading dynamic-font-family">
              <TranslateText label={'PROCESSING_FEE'}>Processing fee</TranslateText>
            </Typography>
            <Typography className="emiDetailsHeading emiDetailsMargin dynamic-font-family">
              <AmountComponent amount={selectedInstrument?.emiMethod?.processingFee?.amount} />
            </Typography>
          </div>
        )}

        <div style={{ display: "flex", margin: "8px 0px 12px 0px" }}>
          <Typography className="noteHeading dynamic-font-family">
            <TranslateText label={'CARD_CHARGED_AMOUNT'}>Your card will be charged for an amount of</TranslateText>{" "}
            <AmountComponent amount={selectedInstrument?.emiMethod?.netAmount} styles={{ fontWeight: 700 }} />{" "}

            {/* <AmountComponent amount={sessionDetails?.paymentDetails?.money?.amountLocaleFull} styles={{ fontWeight: 700 }} />{". "} */}
            {/* {
              paymentInfo?.emiMethod?.applicableOffer?.discount?.type && (
                <>
                  <TranslateText label={'AS_A'}>As a </TranslateText>{" "}
                  {
                    paymentInfo?.emiMethod?.applicableOffer?.discount?.type === "NoCostEmi" ?
                      <TranslateText label={'NO_COST'}>No cost</TranslateText> : <TranslateText label={'LOW_COST'}>Low cost</TranslateText>
                  }
                  {" "}<TranslateText label={'EMI_OFFER'}> EMI offer</TranslateText>{" "}
                </>
              )
            } */}
            <TranslateText label={'CHARGED_INTEREST'}>Your card will be charged interest of</TranslateText>{" "}
            <AmountComponent amount={selectedInstrument?.emiMethod?.interestChargedAmountLocaleFull} styles={{ fontWeight: 700 }} />{" "}

            <TranslateText label={'BANK_TOTAL_PAYABLE_AMOUNT'}>by the bank making the total payable amount as</TranslateText>{" "}
            <AmountComponent amount={selectedInstrument?.emiMethod?.totalAmountLocaleFull} styles={{ fontWeight: 700 }} />{" "}
          </Typography>
        </div>

        {selectedInstrument?.emiMethod?.processingFee && (
          <>
            <div style={{ display: "flex", margin: "8px 0px 12px 0px" }}>
              <Typography className="noteHeading dynamic-font-family">
                <TranslateText label={'PROCESSING_FEE_AMOUNT'}>A processing fee of</TranslateText>{" "}
                <AmountComponent amount={selectedInstrument?.emiMethod?.processingFee?.amountLocaleFull} styles={{ fontWeight: 700 }} />{" "}
                <span style={{ fontWeight: 700 }}>
                  + <TranslateText label={'GST'}>GST</TranslateText>
                </span>{" "}
                <TranslateText label={'BANK_EMI_TRANSACTION'}>as applicable by the bank on the successful EMI transactions</TranslateText>.
              </Typography>
            </div>
          </>
        )}
      </div>
    </>
  );
}
