import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const getActiveStatus = createAsyncThunk(
  "finalStatus/getActiveStatus",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${baseUrl}/checkout/sessions/${token}/status`
      );
      return response.data;
    } catch (err) {
      return rejectWithValue(err);
    }
  }
);
const initialState = {
  activeStatusData: undefined,
  isActiveLoading: false,
  activeStatusErrorMessage: "",
  activeStatusReason: "",
  appState: "CURRENT"
};
const extractStatusReason = (statusReason) => {
  if (statusReason && statusReason.includes(":")) {
    return statusReason.split(":").slice(1).join(":").trim();
  }
  return statusReason;
};

export const finalStatusSlice = createSlice({
  name: "finalStatus",
  initialState,
  reducers: {
    resetFinalStatusResponse: (state) => {
      return {
        ...state,
        activeStatusData: {},
      };
    },
  },
  extraReducers: {
    [getActiveStatus.pending]: (state) => {
      state.isActiveLoading = true;
    },
    [getActiveStatus.fulfilled]: (state, action) => {
      state.isActiveLoading = false;
      state.activeStatusData = action.payload;
      state.activeStatusReason = extractStatusReason(
        action.payload.statusReason
      );
    },
    [getActiveStatus.rejected]: (state, action) => {
      state.isActiveLoading = false;
      state.sessionData = [];
      state.activeStatusErrorMessage = action.error.payload;
    },
  },
});
export const { resetFinalStatusResponse } = finalStatusSlice.actions;
export default finalStatusSlice.reducer;
