import React, { useEffect } from "react";
import { Skeleton, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllPaymentMethods,
  openAddressComponent,
  removePaymentMethods,
  updateShopperDetails,
} from "../../../slices/PaymentSessionDetailsSlice";
import { getAllSavedAddress } from "../../../slices/SavedAddressSlice";
import {
  selectMobileNumber,
  selectSessionToken,
} from "../../../slices/ShopperInfoSlice";
import { selectIsMobileLayout } from "../../../slices/MobileLayoutSlice";
import { addressAnalyticsEvent } from "../../../Utils/uiAnalytics";
import { isEmpty } from "../../../Utils/utils";

export default function CollpaseAddress({
  setShowAddAddressOption,
  setSelectedCardButton,
  setAddressFieldValue,
}) {
  const dispatch = useDispatch();
  const shopperRef = useSelector((state) => selectMobileNumber(state));
  const sessionToken = useSelector((state) => selectSessionToken(state));
  const updatedShopperDetails = useSelector((state) => state.paymentSessionDetails.updatedShopperDetails);
  const { firstName, lastName, phoneNumber, email, deliveryAddress } = !isEmpty(updatedShopperDetails) ? updatedShopperDetails : {};
  const { labelType, labelName, countryCode, state, city, address1, postalCode } = !isEmpty(deliveryAddress) ? deliveryAddress : {};
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));
  const isShippingAddressDisplay = useSelector((state) => state.paymentSessionDetails.isShippingAddressDisplay);
  const { showEmailAddress, showFullName, showContactNumber } = useSelector((state) => state.additionalComponents)
  const showLogin = useSelector((state) => state.additionalComponents.showLogin);
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);
  const latestSavedAddress = useSelector((state) => state.paymentSessionDetails.latestSavedAddress);
  const guestCheckout = useSelector((state) => state.shopperInfo.guestCheckout);
  const handleEditButton = () => {
    setSelectedCardButton("");
    dispatch(openAddressComponent());
    dispatch(removePaymentMethods());
    if (!guestCheckout && showLogin === true) {
      dispatch(getAllSavedAddress({ shopperRef, sessionToken, checkoutToken: sessionDetails?.token }));
      setShowAddAddressOption(false);
    } else {
      const selectedCardData = latestSavedAddress;
      setShowAddAddressOption(true);
      setAddressFieldValue(selectedCardData);
    }
  };

  useEffect(() => {
    addressAnalyticsEvent(sessionDetails, dispatch);
  }, []);
  useEffect(() => {
    if (latestSavedAddress) {
      const selectedCardData = latestSavedAddress;
      dispatch(updateShopperDetails({ selectedCardData }));
      dispatch(
        getAllPaymentMethods({
          token: sessionDetails?.token,
          countryCode: selectedCardData?.countryCode,
        })
      );
    }
  }, [latestSavedAddress]);

  const normalizeText = (input) => {
    const cleanedInput = input.replace(/^\s*\|\s*|\s*\|\s*$/g, '');
    return cleanedInput;
  }

  const getCollapsedAdressDetails = ({
    firstName,
    lastName,
    phoneNumber,
    email,
    labelType,
    labelName,
    countryCode,
    state,
    city,
    address1,
    postalCode
  }) => {
    let text = '';
    text = labelType === 'Other' ? labelName :
      (isShippingAddressDisplay || showFullName ? (
        ((firstName ? `${firstName} ` : '') + (lastName ? `${lastName} ` : ''))) : '');
    text += !isEmpty(text) ? ' | ' : ' ';
    text += ((isShippingAddressDisplay || showContactNumber) && phoneNumber ? `${phoneNumber} | ` : '')
      + ((isShippingAddressDisplay || showEmailAddress) && email ? `${email} | ` : '')
      + (address1 ? `${address1} | ` : '')
      + (city ? `${city} | ` : '')
      + (state ? `${state} | ` : '')
      + (countryCode ? `${countryCode} | ` : '')
      + (postalCode ? `${postalCode} | ` : '');
    return normalizeText(text)
  }

  return (
    <>
      <div className="collapseAddressDiv">
        {Object.keys(updatedShopperDetails)?.length ? (
          <div className="shopperAddressDiv">
            <div>
              {isMobileLayout ? (
                <>
                  <div>
                    <div className="displayFlex" style={{ marginRight: "4px" }}>
                      <Typography className="Margin4Right collapseAddessData dynamic-font-family">
                        {
                          !isEmpty(updatedShopperDetails) && getCollapsedAdressDetails({ labelType, labelName, firstName, lastName })
                        }
                      </Typography>{" "}
                      {!guestCheckout &&
                        updatedShopperDetails?.deliveryAddress?.labelType && (
                          <>
                            <Typography className="dynamic-font-family">
                              |{" "}
                            </Typography>
                            <div className="savedAddressTypeDiv dynamic-font-family">
                              <Typography>
                                {
                                  updatedShopperDetails?.deliveryAddress
                                    ?.labelType
                                }
                              </Typography>
                            </div>
                          </>
                        )}{" "}
                    </div>
                    <Typography className="Margin4Right collapseAddressData dynamic-font-family">
                      {
                        !isEmpty(updatedShopperDetails) && getCollapsedAdressDetails({ phoneNumber, email })
                      }
                    </Typography>
                  </div>
                </>
              ) : (
                <>
                  <div className="displayFlex">
                    <Typography className="Margin4Right collapseAddressData dynamic-font-family">
                      {
                        !isEmpty(updatedShopperDetails) &&
                        getCollapsedAdressDetails({ labelType, labelName, firstName, lastName, phoneNumber, email })
                      }
                    </Typography>{" "}
                    {updatedShopperDetails?.deliveryAddress?.labelType && (
                      <Typography
                        className="dynamic-font-family"
                        style={{ marginLeft: "4px" }}
                      >
                        |
                      </Typography>
                    )}
                    {!guestCheckout &&
                      updatedShopperDetails?.deliveryAddress?.labelType && (
                        <div className="savedAddressTypeDiv dynamic-font-family">
                          <Typography>
                            {updatedShopperDetails?.deliveryAddress?.labelType}
                          </Typography>
                        </div>
                      )}{" "}
                  </div>
                </>
              )}

              {isShippingAddressDisplay && (
                <div>
                  <Typography className="collapseAddressData dynamic-font-family">
                    {
                      !isEmpty(updateShopperDetails) && getCollapsedAdressDetails({ address1, city, state, countryCode, postalCode })
                    }
                  </Typography>
                </div>
              )}
            </div>
            <div className="editIcon" onClick={() => handleEditButton()}>
              <img src="/assets/address/edit-contained.svg" alt="edit" />
            </div>
          </div>
        ) : (
          <Skeleton active paragraph={{ rows: 1 }} />
        )}
      </div>
    </>
  );
}
