import React, { useState, useEffect } from "react";
import { Collapse, Typography } from "antd";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { generateDynamicStyling, getNetAmount, isEmpty, numberToCurrency } from "../../../Utils/utils";
import {
  selectCurrencyCode,
  selectCurrencySymbol,
  selectToatalAmount,
} from "../../../slices/PaymentSessionDetailsSlice";
import "../fontStyling.css";
import getSymbolFromCurrency from "currency-symbol-map";
import TranslateText from "../../resuableComponents/TranslateText";
import { selectSelectedIntrument } from "../../../slices/SavedInstrumentSlice";
import { selectActivePaymentMethod } from "../../../slices/PaymentButtonSlice";

export const PaymentSummaryMobileContainer = ({ children }) => {
  const { t } = useTranslation();
  const currencyCode = useSelector((state) => selectCurrencyCode(state));
  const currencySymbol = useSelector((state) => selectCurrencySymbol(state));
  const amount = useSelector((state) => selectToatalAmount(state));
  const showTotalPaymentSummaryField = useSelector((state) => state.additionalComponents.showTotalPaymentSummaryField);
  const [activeKey, setActiveKey] = useState(showTotalPaymentSummaryField ? "paymentSummary" : null);
  const activePaymentMethod = useSelector((state) => selectActivePaymentMethod(state));
  const selectedInstrument = useSelector((state) => selectSelectedIntrument(state));
  const paymentDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails.paymentDetails);
  const dccQuotation = useSelector(state => state.cardDetails.dccQuotation);
  const dccMoney = dccQuotation?.dccQuotationDetails?.dccMoney?.amount;
  const isDccQuotationSelected = useSelector((state) => state.cardDetails.isDccQuotationSelected);

  useEffect(() => {
    setActiveKey(showTotalPaymentSummaryField ? "paymentSummary" : null);
  }, [showTotalPaymentSummaryField]);

  const handleCollapseChange = (key) => {
    setActiveKey(key);
  };

  const getAmount = (isDcc) => getNetAmount({ isDcc, activePaymentMethod, selectedInstrument, dccMoney, amount });

  const getExtra = () => {
    return (
      <Typography
        className="semiBold12Heading dynamic-font-family"
        style={{ color: "var(--Neutral-Grey-1000)" }}
      >
        {
          !activeKey?.includes('paymentSummary') && <>
            <span className="currency-symbol">
              {getSymbolFromCurrency(currencyCode)}{" "}
            </span>
            {numberToCurrency(getAmount(isDccQuotationSelected), currencyCode)}
          </>
        }
      </Typography>
    );
  };



  return (
    <Collapse
      expandIconPosition="end"
      accordion
      activeKey={activeKey}
      onChange={handleCollapseChange}
      items={[
        {
          key: "paymentSummary",
          label: (
            <Typography className="domestic-ps-mobile-header dynamic-font-family">
              <TranslateText label={"PAYMENT_SUMMARY"}>Payment Summary</TranslateText>
            </Typography>
          ),
          children: <div className="dynamic-font-family">{children}</div>,
          extra: !showTotalPaymentSummaryField && getExtra(),
        },
      ]}
    />
  );
};
