import React, { useEffect, useState } from "react";
import { Checkbox, Divider, Form, Input, Typography } from "antd";
import { EditOutlined, InfoCircleOutlined } from "@ant-design/icons";
import "./shopperLogin.css";
import CustomButton from "../../resuableComponents/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import {
  handleGuestCheckout,
  handleGuestLogedIn,
  handleKeepSignIn,
  loginOtpInitiation,
  loginOtpVerification,
  resetOtpInitiationError,
  resetOtpModal,
  resetOtpValidationError,
} from "../../../slices/ShopperInfoSlice";
import {
  blockInvalidChar,
  formatOtpTime,
  generateDynamicStyling,
} from "../../../Utils/utils";
import "../fontStyling.css";
import timer_worker_script from "../../../web-workers/timerWorker";
import { removeAllSavedAddress } from "../../../slices/SavedAddressSlice";
import {
  collpaseAddressComponent,
  openAddressComponent,
  removeAddressfield,
  updateSavedAddressData,
  updateShopperDetails,
} from "../../../slices/PaymentSessionDetailsSlice";
import ErrorModal from "../modals/errorModal/ErrorModal";
import { CountryData } from "../../../staticData/countryData";
import TranslateText from "../../resuableComponents/TranslateText";
import { useTranslation } from "react-i18next";

const ShopperLogin = () => {
  const { t, i18n } = useTranslation();
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);
  const loginOtpVerificationErrMsg = useSelector((state) => state.shopperInfo?.loginOtpVerificationErrMsg);
  const loginOtpInitiationErrorMsg = useSelector((state) => state.shopperInfo?.loginOtpInitiationErrorMsg);
  const otpInitiated = useSelector((state) => state.shopperInfo?.otpInitiated);
  const otpVerificationInitiated = useSelector((state) => state.shopperInfo?.otpVerificationInitiated);
  const showOtpModal = useSelector((state) => state.shopperInfo?.showOtpModal);
  const [phoneNumber, setPhoneNumber] = useState();
  const [otp, setOtp] = useState();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [clientReady, setClientReady] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [resendOtp, setResendOtp] = useState(true);
  const [phoneNumberErrors, setPhoneNumberErrors] = useState();
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    if (sessionDetails) {
      const normalizedPhoneNumber =
        sessionDetails?.paymentDetails?.shopper?.phoneNumber?.startsWith("+")
          ? sessionDetails?.paymentDetails?.shopper?.phoneNumber
          : `+${sessionDetails?.paymentDetails?.shopper?.phoneNumber}`;

      const dialCode = CountryData?.find((country) =>
        normalizedPhoneNumber?.startsWith(country.dial_code)
      );
      const phoneNumberWithoutDialCode = dialCode
        ? normalizedPhoneNumber.replace(dialCode.dial_code, "")
        : normalizedPhoneNumber;
      setPhoneNumber(phoneNumberWithoutDialCode);
      form.setFieldsValue({ phoneNumber: phoneNumberWithoutDialCode });
    }
  }, [sessionDetails, form]);

  // form error
  useEffect(() => {
    setClientReady(true);
  }, []);

  useEffect(() => {
    const errorStyling = document.querySelectorAll([
      ".ant-input-group-addon",
      ".ant-input",
    ]);
    errorStyling?.forEach((element) => {
      element.style.borderColor =
        loginOtpInitiationErrorMsg || phoneNumberErrors ? "#ff4d4f" : "";
    });
  }, [phoneNumberErrors]);

  useEffect(() => {
    if (sessionDetails) {
      generateDynamicStyling(sessionDetails);
    }
    if (showOtpModal) {
      setResendOtp(false);
    }
  }, [showOtpModal]);

  useEffect(() => {
    if (!resendOtp) {
      const timerWorker = new Worker(timer_worker_script);
      timerWorker.postMessage({ duration: 30 });
      timerWorker.onmessage = ({ data }) => {
        if (data === "done") {
          setResendOtp(true);
          clearInterval(timerWorker);
          setCountdown(0);
        } else {
          setCountdown(data);
        }
      };
      if (!showOtpModal) {
        timerWorker.terminate();
      }
      return () => {
        timerWorker.terminate();
      };
    }
  }, [resendOtp]);

  // otp screen functions

  const handleOtpVerification = () => {
    const mobileNumber = "+91" + phoneNumber;
    dispatch(loginOtpVerification({ mobileNumber, otp, checkoutToken: sessionDetails?.token }));
  };
  const handleOtp = (e) => {
    e.target.value = e.target.value.slice(0, 6);
    dispatch(resetOtpValidationError());
    setOtp(e.target.value);
  };
  const handleResendCode = () => {
    setOtp("");
    setResendOtp(false);
    handleInitiateOtp();
    setCountdown(30);
  };
  const handleMobileNumberEdit = () => {
    dispatch(resetOtpModal());
    dispatch(resetOtpInitiationError());
    dispatch(resetOtpValidationError());
    setOtp("");
    setResendOtp(true);
    setCountdown(30);
  };

  const handleInitiateOtp = () => {
    if (phoneNumber && clientReady) {
      dispatch(
        loginOtpInitiation({
          mobileNumber: "+91" + phoneNumber,
          keepSignedIn: checked,
          checkoutToken: sessionDetails?.token
        })
      );
      dispatch(removeAddressfield());
      dispatch(openAddressComponent());
      sessionStorage.removeItem("selectedPhoneFlagSet");
    }
  };

  const phoneeNumberErrors = (number) => {
    if (!number) {
      setPhoneNumberErrors(t("MOBILE_REQUIRED"));
    } else if (number?.length != 10) {
      setPhoneNumberErrors(t("MOBILE_NO_10_DIGIT"));
    } else {
      setPhoneNumberErrors("");
    }
  };

  useEffect(() => {
    const handleLanguageChange = () => {
      if (phoneNumberErrors) phoneeNumberErrors(phoneNumber);
    };
    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n, phoneNumber]);

  const handelPhoneChange = (e) => {
    const telNo = e.target.value;
    const re = /^[0-9\b]+$/;

    if (telNo === "" || re.test(telNo)) {
      setPhoneNumber(telNo);
      phoneeNumberErrors(telNo);
      dispatch(resetOtpInitiationError());
    }
  };
  const onChangeCheckbox = (e) => {
    setChecked(e.target.checked);
  };

  useEffect(() => {
    if (checked) {
      dispatch(handleKeepSignIn(checked));
    } else {
      dispatch(handleKeepSignIn(checked));
    }
  }, [checked, dispatch]);
  const handleGuestLogin = () => {
    dispatch(handleGuestCheckout(true));
    dispatch(removeAllSavedAddress());
    dispatch(handleGuestLogedIn(true));
    if (
      (sessionDetails?.paymentDetails?.shopper?.firstName ||
        sessionDetails?.paymentDetails?.shopper?.lastName) &&
      sessionDetails?.paymentDetails?.shopper?.email &&
      sessionDetails?.paymentDetails?.shopper?.deliveryAddress
    ) {
      const selectedCardData = {
        name: `${sessionDetails?.paymentDetails?.shopper?.firstName || ""} ${sessionDetails?.paymentDetails?.shopper?.lastName || ""
          }`.trim(),
        address1:
          sessionDetails?.paymentDetails?.shopper?.deliveryAddress?.address1,
        address2:
          sessionDetails?.paymentDetails?.shopper?.deliveryAddress?.address2,
        city: sessionDetails?.paymentDetails?.shopper?.deliveryAddress?.city,
        state: sessionDetails?.paymentDetails?.shopper?.deliveryAddress?.state,
        countryCode:
          sessionDetails?.paymentDetails?.shopper?.deliveryAddress?.countryCode,
        postalCode:
          sessionDetails?.paymentDetails?.shopper?.deliveryAddress?.postalCode,
        email: sessionDetails?.paymentDetails?.shopper?.email,
        phoneNumber: sessionDetails?.paymentDetails?.shopper?.phoneNumber,
      };
      dispatch(updateSavedAddressData(selectedCardData));
      dispatch(updateShopperDetails({ selectedCardData }));
      dispatch(collpaseAddressComponent());
    }
  };

  return (
    <div className="">
      {loginOtpInitiationErrorMsg && (
        <ErrorModal
          icon={
            <InfoCircleOutlined
              style={{ color: "#FF4D4F", fontSize: "22px" }}
            />
          }
          title={t("LOGIN_FAILED")}
          desc={loginOtpInitiationErrorMsg}
          showModal={loginOtpInitiationErrorMsg}
          primaryOnClick={() => {
            dispatch(resetOtpInitiationError());
            dispatch(resetOtpValidationError());
            handleGuestLogin();
          }}
          secondaryOnClick={() => {
            dispatch(resetOtpInitiationError());
            dispatch(resetOtpValidationError());
          }}
          primaryBtnText={"CONTINUE_AS_GUEST"}
          secondaryBtnText={"EDIT_MOBILE_NUMBER"}
          showSecondaryButton={true}
        />
      )}
      <Form layout="vertical" name="shopperLogin" form={form}>
        {!showOtpModal ? (
          <div className="login-flex">
            <div className="loginInputWidth">
              <div>
                <Form.Item
                  label={
                    <Typography className="shopper-login-code-label dynamic-font-family">
                      <TranslateText label={"MOBILE_NUMBER"}>Mobile Number</TranslateText> *
                    </Typography>
                  }
                  name="mobileNumber"
                >
                  <Input
                    type="number"
                    onKeyDown={blockInvalidChar}
                    value={phoneNumber}
                    addonBefore="+91"
                    onChange={handelPhoneChange}
                    placeholder={t("MOBILE_NUMBER")}
                    className="mobile-no-input"
                    inputMode="numeric"
                    pattern="[0-9]*"
                  />
                  <div className="shopper-loggin-resend-code-error dynamic-font-family">
                    <Typography
                      className="regular12Heading dynamic-font-family"
                      style={{ color: "var(--Red-800)" }}
                    >
                      {phoneNumberErrors}
                    </Typography>
                  </div>
                </Form.Item>
              </div>
              <Checkbox
                className="checkboxText"
                checked={checked}
                onChange={onChangeCheckbox}
              >
                <TranslateText label={"KEEP_SIGNED"}>Keep me signed in</TranslateText>
              </Checkbox>
              <CustomButton
                text={t("CONTINUE")}
                buttonType={"primary-btn"}
                onClick={
                  showOtpModal ? handleOtpVerification : handleInitiateOtp
                }
                htmlType="submit"
                block={true}
                loading={showOtpModal ? otpVerificationInitiated : otpInitiated}
                disabled={
                  !showOtpModal &&
                  (!clientReady ||
                    phoneNumber?.length !== 10 ||
                    phoneNumberErrors)
                }
              />
            </div>
            <div className="guestverticalDivider loginDividerWidth">
              <div className="verticalDivider">
                <Divider type="vertical" />
                <span style={{ margin: "20px 0px" }}><TranslateText label={"OR_CAPS"}>OR</TranslateText></span>
                <Divider type="vertical" />
              </div>
            </div>
            <div className="guestHorizontalDivider">
              <Divider><TranslateText label={"OR_CAPS"}>OR</TranslateText></Divider>
            </div>
            <div
              className="loginInputWidth"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "end",
              }}
            >
              <Typography
                className="shopper-login-code-label dynamic-font-family"
                style={{ paddingTop: "6px" }}
              >
                <TranslateText label={"GUEST_CHECKOUT"}>Guest Checkout</TranslateText>
              </Typography>
              <Typography className="guest-Heading">
                <TranslateText label={"PROCEED_WITHOUT_CREATING_ACCOUNT"}>Proceed to checkout without creating an account</TranslateText>.
              </Typography>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <CustomButton
                  text={t("CONTINUE_AS_GUEST")}
                  buttonType={"primary-btn"}
                  block={true}
                  containerType={""}
                  htmlType="submit"
                  onClick={() => handleGuestLogin()}
                />{" "}
              </div>
            </div>
          </div>
        ) : (
          <div className="login-flex">
            <div className="loginInputWidth">
              <Form.Item
                label={
                  <div className="dynamic-font-family shopper-login-code-label">
                    <TranslateText label={"SENT_6_DIGIT_CODE"}>We have sent a 6-digit code to</TranslateText>
                    {" "}
                    <span
                      className="dynamic-font-family shopper-login-code-ph"
                      style={{ cursor: "pointer", textDecoration: "underline" }}
                      onClick={handleMobileNumberEdit}
                    >
                      {phoneNumber}{" "}
                      <EditOutlined style={{ height: "18px", width: "18px" }} />
                    </span>
                  </div>
                }
                name="otp"
              >
                <Input
                  type="number"
                  value={otp}
                  onChange={handleOtp}
                  onKeyDown={blockInvalidChar}
                  placeholder={t("ENTER_CODE")}
                  style={{
                    borderColor: loginOtpVerificationErrMsg ? "#ff4d4f" : "",
                  }}
                />
                <div className="shopper-loggin-resend-code-error dynamic-font-family">
                  <Typography
                    className="regular12Heading dynamic-font-family"
                    style={{ color: "var(--Red-800)" }}
                  >
                    {loginOtpVerificationErrMsg && "Invalid Code"}
                  </Typography>
                  <div className="shopper-login-resend-code">
                    {!resendOtp ? (
                      <Typography className="regular12Heading resend-code-inactive dynamic-font-family">
                        <TranslateText></TranslateText>
                        <TranslateText label={"RESEND_CODE_IN"}>Resend code in</TranslateText>{" "}
                        <span className="resend-code-timer dynamic-font-family">
                          {formatOtpTime(countdown)}
                        </span>
                      </Typography>
                    ) : (
                      <Typography
                        className="resend-code-active dynamic-font-family"
                        onClick={handleResendCode}
                      >
                        <TranslateText label={"RESEND_CODE"}>Resend code</TranslateText>
                      </Typography>
                    )}
                  </div>
                </div>
              </Form.Item>
              <CustomButton
                text={t("CONTINUE")}
                buttonType={"primary-btn"}
                onClick={
                  showOtpModal ? handleOtpVerification : handleInitiateOtp
                }
                htmlType="submit"
                block={true}
                loading={showOtpModal ? otpVerificationInitiated : otpInitiated}
                disabled={!otp}
              />
            </div>
            <div className="guestverticalDivider loginDividerWidth"></div>
            <div className="loginInputWidth"></div>
          </div>
        )}
      </Form>

      <div className="domestic-shopper-login-tnc dynamic-font-family" style={{ marginTop: "12px" }}  >
        <TranslateText label={"BY_PROCEEDING_I_ACCEPT_THE"}>By proceeding, I accept the</TranslateText>
        {" "}
        <a href="https://www.boxpay.tech/terms-conditions" target="_blank">
          <TranslateText label={"TERMS_AND_CONDITION"}>Terms & Conditions</TranslateText>
        </a>{" "}
        <span><TranslateText label={"AND"}> and </TranslateText></span>
        {" "}
        <a href="https://www.boxpay.tech/privacy-policy" target="_blank">
          <TranslateText label={"PRIVACY_POLICY"}> Privacy Policy</TranslateText>
        </a>
      </div>
    </div>
  );
};

export default ShopperLogin;
