import React, { createContext, useState } from "react";

const PaymentContext = createContext();
export default PaymentContext;

export const PaymentProvider = (props) => {
    const [popUpWindowIframeIos, setPopUpWindowIframeIos] = useState();
    const [isDccSelected, setIsDccSelected] = useState('');

    const values = {
        popUpWindowIframeIos,
        setPopUpWindowIframeIos,
        isDccSelected,
        setIsDccSelected
    }

    return (
        <PaymentContext.Provider value={values}>
            {props.children}
        </PaymentContext.Provider>
    )
}