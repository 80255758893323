import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isMobileLayout: false,
  isTabletLayout: false,
  isIphone: false,
  isAndroid: false,
  isNativeApp: false,
  isSafari: false,
  isChromeIos: false,
};

export const mobileLayoutSlice = createSlice({
  name: "mobileLayout",
  initialState,
  reducers: {
    toggleMobileLayout(state, action) {
      state.isMobileLayout = action.payload;
    },
    toggleTabletLayout(state, action) {
      state.isTabletLayout = action.payload;
    },
    setIphone(state, action) {
      state.isIphone = action.payload;
    },
    setAndroid(state, action) {
      state.isAndroid = action.payload;
    },
    setNativeApp(state, action) {
      state.isNativeApp = action.payload;
    },
    setSafari(state, action) {
      state.isSafari = action.payload;
    },
    setChromeIos(state, action) {
      state.isChromeIos = action.payload;
    },
  },
});
export const { toggleMobileLayout, toggleTabletLayout, setIphone, setAndroid, setNativeApp, setSafari, setChromeIos } = mobileLayoutSlice.actions;

export const selectIsMobileLayout = (state) =>
  state?.mobileLayout?.isMobileLayout;

export const selectIsTabletLayout = (state) =>
  state?.mobileLayout?.isTabletLayout;

export default mobileLayoutSlice.reducer;
