import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as jose from "jose";
import { importKey } from "./importKey";
import { Input, Typography } from "antd";
import {
  cardCvcData,
  cardExpiryDateData,
  cardHolderNameData,
  cardNumberData,
} from "../slices/CardSlice";
import InnerHTML from "dangerously-set-html-content";
import { getPopDimensionDetails, isEmpty, isFireFox } from "./utils";
import { selectPopWindowIframeUpiIntent } from "../slices/PaymentButtonSlice";
import { useTranslation } from "react-i18next";
import { resetPaymentInitiation, resetPaymentInitiationLoading } from "../slices/PaymentInitiationSlice";
import ErrorModal from "../ant-design/components/modals/errorModal/ErrorModal";
import { InfoCircleOutlined } from "@ant-design/icons";
import PaymentContext from "../context/payment.context";

const WAIT_TIME_FOR_UPI_INTENT_PAYMENT = 12000;
const PaymentResponseHandler = ({ isIframe, popWindowIframeRef, stopSatusCall = () => { } }) => {
  const { t } = useTranslation();
  const { popUpWindowIframeIos } = useContext(PaymentContext);
  const dispatch = useDispatch();
  const [response, setResponse] = useState(undefined);
  const [showErroModalForUpiIntent, setShowErroModalForUpiIntent] = useState(false);
  const paymentInitiationData = useSelector((state) => state.paymentInitiation.paymentInitiationData);
  const cardNumber = useSelector((state) => cardNumberData(state));
  const cardHolderName = useSelector((state) => cardHolderNameData(state));
  const cardExpiryDate = useSelector((state) => cardExpiryDateData(state));
  const cardCvc = useSelector((state) => cardCvcData(state));
  const formDivRef = useRef();
  const formRef = useRef();
  const [processedResponse, setProcessedResponse] = useState();
  const { isIphone, isAndroid, isChromeIos } = useSelector((state) => state.mobileLayout);
  const hasUserLeftTheCheckoutScreenRef = useRef(false);
  const isIosUserHasBeenNotifiedRef = useRef(false);
  const isAndroidUserNotifiedForOtherUPIs = useRef(false);
  const erroModalDescriptionText = useRef("PAYMENT_COULDNOT_PROCESSED");
  const popUpWindowForIntentForAndroidRef = useRef();
  const popUpWindowAndroidIntervalRef = useRef();
  const iosSafariIntervalRef = useRef();

  useEffect(() => {
    if (paymentInitiationData === undefined && response === undefined) {
      return;
    }
    if (paymentInitiationData !== response) {
      setResponse(paymentInitiationData);
    }
  }, [paymentInitiationData]);
  const isValidUrl = (urlString) => {
    var urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // validate protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
      "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // validate fragment locator
    return !!urlPattern.test(urlString);
  };
  useEffect(() => {
    (async () => {
      const encryptionWork = {};
      const encryptAction = response?.actions?.find(
        (action) => action.type === "encrypt"
      );
      if (encryptAction) {
        const publicKey = await importKey(encryptAction.encryptionKey);

        const [expiry_month, expiry_year] = cardExpiryDate.split("/");
        const cardNo = cardNumber?.replace(/\s/g, "");
        const cardDetails = {
          number: cardNo,
          expiry_month: expiry_month,
          expiry_year: `20${expiry_year}`,
          holder_name: cardHolderName,
          cvv: cardCvc,
        };

        const jwe = await new jose.CompactEncrypt(
          new TextEncoder().encode(JSON.stringify(cardDetails))
        )
          .setProtectedHeader({
            alg: encryptAction.algo,
            enc: "A128GCM",
            kid: encryptAction.keyId,
            "x5t#S256": encryptAction.certificateFingerprint,
          })
          .encrypt(publicKey);

        encryptionWork[encryptAction.outputField] = jwe;
        setProcessedResponse((prev) => {
          const newResponse = { ...response };
          newResponse.actions = response?.actions?.map((action) => {
            const newAction = { ...action };
            newAction.data = { ...action.data, ...encryptionWork };
            return newAction;
          });
          return newResponse;
        });
      } else {
        setProcessedResponse(response);
      }
    })();
  }, [response]);
  const isAutoSubmitForm = () => {
    if (isEmpty(processedResponse)) return
    let isHtml = processedResponse?.actions?.some(d => d.type === 'html');
    return isHtml;
  };
  const getPaymentCompletionUrl = (url) => {
    const isLocal = window.location.hostname === 'localhost';
    if (isLocal) {
      const localUrl = url.replace('https://test-checkout.boxpay.tech', `http://${window.location.host}`);
      return localUrl;
    }
    return url;
  }
  const getFormDataForRedirection = () => {
    let formArr = [];
    let actionData = processedResponse?.actions;
    if (isEmpty(actionData)) return formArr[0];
    actionData.forEach((action) => {
      if (action?.type === "redirect") {
        if (isValidUrl(action?.url)) {
          const formElement = document.createElement('form');
          formElement.method = action?.method;
          // formElement.action = getPaymentCompletionUrl(action?.url);
          formElement.action = action?.url;

          // add input
          Object.entries(addQueryParamsToObject(action?.url, action?.data)).forEach((entry, i) => {
            const input = document.createElement('input');
            input.key = i
            input.type = 'hidden';
            input.name = entry[0];
            input.value = entry[1];
            formElement.appendChild(input);
          })
          formArr.push(formElement)
        }
      } else if (action?.type === "html" && action?.htmlPageString !== undefined) {
        if (isIframe) {
          return formArr.push(action.htmlPageString)
        }
        if (document.getElementById("autosubmit-form") !== null) {
          formArr.push(document.getElementById("autosubmit-form"));
        }
        if (document.getElementById("payment_post") !== null) {
          formArr.push(document.getElementById("payment_post"));
        }
        if (document.getElementById("threedsChallengeRedirectForm") !== null) {
          formArr.push(document.getElementById("threedsChallengeRedirectForm"));
        }
      } else if (action?.type === "appRedirect" && !isEmpty(formRef.current)) {

        if (isIphone && !isEmpty(popUpWindowIframeIos) && isIframe) {
          hasUserLeftTheCheckoutScreenRef.current = false;
          const formClone = formRef.current.cloneNode(true); // Clone the form with its child elements
          popUpWindowIframeIos.document.body.appendChild(formClone); // Append the cloned form
          formClone.submit();
          handleUpiNotOpenedFallbackForIos(popUpWindowIframeIos);
          // formRef.current.submit();
        } else {
          if (isIphone) {
            formRef.current.submit();
          } else {
            formRef.current.submit();
            handleUpiNotOpenedFallbackForAndroid();
          }
        }
      }
    })
    return formArr[0];
  };
  const handleIframeEvents = () => {
    const { width, height, left, top } = getPopDimensionDetails();
    let redirectionForm = getFormDataForRedirection();
    if (!redirectionForm) return

    if (isEmpty(popWindowIframeRef?.current) || popWindowIframeRef?.current?.closed) {
      popWindowIframeRef.current = window.open('', 'FormPopup', `width=${width},height=${height},top=${top},left=${left}`);
    } else {

    }
    // check if the pop-up window is blocked in user's device
    // if (!popWindowIframeRef.current || popWindowIframeRef.current.closed || typeof popWindowIframeRef.current.closed === 'undefined') {
    //   alert('Popup blocked. Please allow popups for this site.-');
    //   return;
    // }


    if (redirectionForm && !isAutoSubmitForm()) {
      popWindowIframeRef.current.document.body.appendChild(redirectionForm);
      redirectionForm.submit();
    } else {
      const iframeDocument = popWindowIframeRef.current.document;
      iframeDocument.open();
      iframeDocument.write(redirectionForm);
      iframeDocument?.close();
      const form = iframeDocument.querySelector('form');
      if (form) {
        form.submit();
      }
    }
  };

  // UPI INTENT
  const checkIfAndroidHasOpenedUpiApp = () => {
    popUpWindowAndroidIntervalRef.current = setInterval(() => {
      if (popUpWindowForIntentForAndroidRef.current?.closed) {
        hasUserLeftTheCheckoutScreenRef.current = true
        clearInterval(popUpWindowAndroidIntervalRef.current);
        // dispatch(resetPaymentInitiationLoading())
      }
    }, 500);
    setTimeout(() => {
      if (!popUpWindowForIntentForAndroidRef.current?.closed) {
        erroModalDescriptionText.current = "NO_UPI_APPS_FOUND";
        if (!showErroModalForUpiIntent) {
          setTimeout(() => {
            stopSatusCall();
            setShowErroModalForUpiIntent(true);
          }, 500);
        }
        hasUserLeftTheCheckoutScreenRef.current = false;
        popUpWindowForIntentForAndroidRef.current?.close();
      }
    }, 2000)
  }
  const handlePrimaryClick = () => {
    // reset all
    clearInterval(iosSafariIntervalRef.current);
    hasUserLeftTheCheckoutScreenRef.current = false;
    isIosUserHasBeenNotifiedRef.current = false;
    isAndroidUserNotifiedForOtherUPIs.current = false;
    erroModalDescriptionText.current = "PAYMENT_COULDNOT_PROCESSED";
    stopSatusCall();
    setShowErroModalForUpiIntent(false);
    dispatch(resetPaymentInitiationLoading());
    dispatch(resetPaymentInitiation());
  }
  const handleUpiNotOpenedFallbackForAndroid = () => {
    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        // APP OPENED
        hasUserLeftTheCheckoutScreenRef.current = true;
      }
    });
    window.addEventListener('blur', () => {
      isAndroidUserNotifiedForOtherUPIs.current = true;
    })
    setTimeout(() => {
      if (!hasUserLeftTheCheckoutScreenRef.current && !isAndroidUserNotifiedForOtherUPIs.current) {
        // APP NOT OPENED
        erroModalDescriptionText.current = "NO_UPI_APPS_FOUND";
        stopSatusCall();
        setShowErroModalForUpiIntent(true);
        hasUserLeftTheCheckoutScreenRef.current = false;
      }
    }, 2000);
    window.addEventListener('focus', function () {
      // wait if the payment is done or not after opening the UPI app
      setTimeout(() => {
        setShowErroModalForUpiIntent(true);
      }, WAIT_TIME_FOR_UPI_INTENT_PAYMENT);

    });
  }
  const handleUpiNotOpenedFallbackForIos = (doc = window) => {
    // CHROME IOS
    if (isChromeIos) {
      iosSafariIntervalRef.current = setInterval(() => {
        if (document.visibilityState === 'hidden') {
          // page is not visible to the user
        } else {
          // page is visible to the user
          stopSatusCall();
          clearInterval(iosSafariIntervalRef.current);
          setTimeout(() => {
            setShowErroModalForUpiIntent(true);
          }, WAIT_TIME_FOR_UPI_INTENT_PAYMENT);
        }
      }, 500); // Check every 500ms
      return
    } else {
      // SRAFARI
      doc.addEventListener('blur', function () {
        // USER HAS BEEN NOTIFIED ABOUT UPI INTENT APP
        isIosUserHasBeenNotifiedRef.current = true;
      });
      doc.addEventListener('visibilitychange', () => {
        if (document.hidden) {
          // USER LEFT CHECKOUT PAGE
          hasUserLeftTheCheckoutScreenRef.current = true;
        }
      });
      window.addEventListener('focus', () => {
        if (isIosUserHasBeenNotifiedRef.current && hasUserLeftTheCheckoutScreenRef.current) {
          // APP OPENED AND USER IS BACK TO CHECKOUT
          // wait if the payment is done or not after opening the UPI app
          setTimeout(() => {
            stopSatusCall();
            setShowErroModalForUpiIntent(true);
          }, WAIT_TIME_FOR_UPI_INTENT_PAYMENT);
        }
      })
      doc.addEventListener('focus', function () {
        setTimeout(() => {
          if (isIosUserHasBeenNotifiedRef.current && hasUserLeftTheCheckoutScreenRef.current) {
            // APP OPENED
            // close the window popup if the app is opened
            if (isIframe && (isFireFox || isIphone)) {
              doc?.close();
            }
          }
          if (isIosUserHasBeenNotifiedRef.current && !hasUserLeftTheCheckoutScreenRef.current) {
            // USER JUST NOT ALLOWED THE APP TO OPEN OR NO UPI APP ISNTALLED
            isIosUserHasBeenNotifiedRef.current = false;
            stopSatusCall();
            if (isIframe && (isFireFox || isIphone)) {
              doc?.close();
            }
            setTimeout(() => {
              stopSatusCall();
              setShowErroModalForUpiIntent(true);
            }, 1000);
          }
        }, 2000);
      });
    }
  }
  // UPI INTENT

  useEffect(() => {
    if (isEmpty(processedResponse)) return
    // UPI INTENT
    if (!isIframe && processedResponse.paymentMethod.brand === 'UpiIntent') {
      popUpWindowForIntentForAndroidRef.current = undefined;
      if (isIphone) {
        hasUserLeftTheCheckoutScreenRef.current = false;
        handleUpiNotOpenedFallbackForIos();
      } else if (isAndroid) {
        hasUserLeftTheCheckoutScreenRef.current = false;
        handleUpiNotOpenedFallbackForAndroid();
      }
    }
    // UPI INTENT

    // handling redirection forms in Iframe
    if (isIframe && processedResponse !== undefined) {
      return handleIframeEvents();
    }

    // normal flow of payment redirection
    let redirectionForm = getFormDataForRedirection();
    if (processedResponse !== undefined && redirectionForm?.submit !== undefined) {
      formDivRef.current.appendChild(redirectionForm);
      redirectionForm.submit();
    }
    if (processedResponse !== undefined && formRef.current && processedResponse.paymentMethod.brand !== 'UpiIntent') {
      formRef.current.submit();
    }

    // this is needed as html react parser doesn't execute javascript
    if (document.getElementById("autosubmit-form") !== null) {
      document.getElementById("autosubmit-form").submit();
    }
    if (document.getElementById("payment_post") !== null) {
      document.getElementById("payment_post").submit();
    }
    if (document.getElementById("threedsChallengeRedirectForm") !== null) {
      document.getElementById("threedsChallengeRedirectForm").submit();
    }
  }, [processedResponse]);
  const addQueryParamsToObject = (url, data) => {
    if (data === undefined) {
      data = {};
    } else {
      data = JSON.parse(JSON.stringify(data));
    }

    if (url.includes("?")) {
      var queryString = url.split("?")[1];

      // Split the query string into an array of key-value pairs
      var params = queryString.split("&");

      // Loop through each key-value pair
      params.forEach(function (param) {
        var pair = param.split("=");
        var key = decodeURIComponent(pair[0]);
        var value = decodeURIComponent(pair[1] || "");

        // Add the key-value pair to the paramsObject
        data[key] = value;
      });
    }

    return data;
  };
  return (
    <>
      {processedResponse?.actions &&
        processedResponse?.actions?.length > 0 &&
        processedResponse?.actions?.filter((action) => action?.type === "html" && action?.htmlPageString !== undefined).map((action, i) => {
          return (
            isIframe ? (<></>) : (
              // isAutoSubmitForm() && isIframe ? (<></>) : (
              <InnerHTML
                key={`html-${i}`}
                id="dynamic-html-container"
                html={action.htmlPageString}
              />
            )
          );
        })}
      <div ref={formDivRef} />

      {response?.actions?.filter((action) => action?.type === "appRedirect").map((action) => {
        const appUrl = atob(action?.url);
        return (
          <form
            key={appUrl}
            method={action?.method}
            ref={formRef}
            action={appUrl}
          >
            {Object.entries(addQueryParamsToObject(appUrl, action?.data)).map((entry) => {
              return (
                <Input
                  key={entry[0]}
                  name={entry[0]}
                  value={entry[1]}
                  type="hidden"
                />
              );
            }
            )}
          </form>
        );
      })}

      {/* UPI INTENT */}
      <ErrorModal
        icon={
          <InfoCircleOutlined style={{ color: "#FF4D4F", fontSize: "22px" }} />
        }
        title={`${t("PAYMENT_COULDNOT_COMPLETE")}`}
        desc={`${t(erroModalDescriptionText.current)}`}
        showModal={showErroModalForUpiIntent}
        primaryOnClick={() => { handlePrimaryClick() }}
        primaryBtnText={`${t("Ok")}`}
        showSecondaryButton={false}
      />
      {/* UPI INTENT */}
    </>
  );
}

export default React.memo(PaymentResponseHandler);
