/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Divider, Dropdown, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import {
  deleteSavedAddress,
  getAllSavedAddress,
  showGoBackButton,
} from "../../../slices/SavedAddressSlice";
import CustomButton from "../../resuableComponents/CustomButton";
import ErrorModal from "../modals/errorModal/ErrorModal";
import { updateSavedAddressData } from "../../../slices/PaymentSessionDetailsSlice";
import { selectIsMobileLayout } from "../../../slices/MobileLayoutSlice";
import MobileAddressButton from "./mobileAddressButon";
import {
  selectMobileNumber,
  selectSessionToken,
} from "../../../slices/ShopperInfoSlice";
import TranslateText from "../../resuableComponents/TranslateText";

export default function SavedAddress({
  handleSelectedEditAddress,
  handleSelectedCardData,
  handleButtonClicked,
  setAddressFieldValue,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const shopperRef = useSelector((state) => selectMobileNumber(state));
  const sessionToken = useSelector((state) => selectSessionToken(state));
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [selectedCardData, setSelectedCardData] = useState("");
  const [deleteItemData, setDeleteItemData] = useState({});
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const allSavedAddresses = useSelector((state) => state.savedAddressDetails.allSavedAddresses);
  const deleteSavedAddressData = useSelector((state) => state.savedAddressDetails.deleteSavedAddressData);
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);

  const handleEditClick = (item) => {
    handleSelectedEditAddress(item);
  };

  const handleRemoveButton = (item) => {
    setDeleteItemData(item);
    setShowDeleteModal(true);
  };

  useEffect(() => {
    sessionStorage.removeItem("selectedPhoneFlagSet");
  }, []);

  const items = (item) => [
    {
      key: "1",
      label: "Edit",
      icon: <EditOutlined />,
      onClick: () => handleEditClick(item),
    },
    {
      key: "2",
      label: "Remove",
      icon: <DeleteOutlined />,
      onClick: () => handleRemoveButton(item),
    },
  ];

  const handleSavedAddressCard = (item, idx) => {
    setSelectedCardIndex(idx);
    setSelectedCardData(item);
    handleSelectedCardData(item);
  };
  const handleDeleteButton = () => {
    const addressRef = deleteItemData?.addressRef;
    dispatch(deleteSavedAddress({ shopperRef, sessionToken, addressRef, checkoutToken: sessionDetails?.token }));
    setShowDeleteModal(false);
    setAddressFieldValue({});
  };

  useEffect(() => {
    if (Object.keys(deleteSavedAddressData)?.length > 0) {
      dispatch(getAllSavedAddress({ shopperRef, sessionToken, checkoutToken: sessionDetails?.token }));
    }
  }, [deleteSavedAddressData]);
  useEffect(() => {
    if (Object.keys(selectedCardData)?.length > 0) {
      setButtonDisable(false);
    }
  }, [selectedCardData]);

  const handleSubmit = () => {
    dispatch(updateSavedAddressData(selectedCardData));
    handleButtonClicked(true);
  };

  useEffect(() => {
    if (allSavedAddresses?.length === 1) {
      setSelectedCardIndex(0);
      setSelectedCardData(allSavedAddresses[0]);
      handleSelectedCardData(allSavedAddresses[0]);
    }
  }, [allSavedAddresses]);

  useEffect(() => {
    if (isMobileLayout) {
      dispatch(showGoBackButton(false));
    }
  }, [isMobileLayout]);
  return (
    <>
      <div className="addressSavedDivider">
        <Divider orientation="left">
          {allSavedAddresses?.length > 1 ? t("SAVED_ADDRESSES") : t("SAVED_ADDRESS")}
        </Divider>
      </div>
      <div className="savedAddressFields savedAddressScroll">
        {allSavedAddresses?.map((item, idx) => {
          return (
            <div
              className={`savedAddressCard ${selectedCardIndex === idx ? "selectedCard" : ""}`}
              key={idx}
            >
              <div className="savedAddressDiv">
                <div
                  className="displayFlex"
                  style={{ width: "90%" }}
                  onClick={() => handleSavedAddressCard(item, idx)}
                >
                  <div className="iconMarginRight">
                    {item?.labelType === "Home" ? (
                      <img src="/assets/address/home.svg" alt="home" />
                    ) : item?.labelType === "Work" ? (
                      <img src="/assets/address/office.svg" alt="home" />
                    ) : (
                      <img src="/assets/address/other.svg" alt="other" />
                    )}
                  </div>
                  <div>
                    <div className="displayFlex">
                      <Typography className="medium14Heading deliveryTitle dynamic-font-family">
                        {item?.labelType === "Other"
                          ? item?.labelName
                          : item?.name}
                      </Typography>
                      <div className="savedAddressTypeDiv">
                        <Typography className="dynamic-font-family">
                          {item?.labelType}
                        </Typography>
                      </div>
                    </div>
                    <div className="displayFlex">
                      <Typography className="medium14Heading orderItemHeading dynamic-font-family">
                        <TranslateText label={"MOBILE"}>Mobile</TranslateText>:
                      </Typography>
                      <Typography
                        className="medium14Heading deliveryTitle dynamic-font-family"
                        style={{ marginLeft: "4px" }}
                      >
                        {item?.phoneNumber}
                      </Typography>
                    </div>
                    <div className="overflowText">
                      <Typography className="medium12Heading orderItemHeading dynamic-font-family">
                        {item?.address1}, {item?.city}, {item?.state},{" "}
                        {item?.postalCode}
                      </Typography>
                    </div>
                  </div>
                </div>
                <div>
                  <Dropdown
                    menu={{
                      items: items(item),
                    }}
                  >
                    <a onClick={(e) => e.preventDefault()}>
                      <MoreOutlined className="moreOption" />
                    </a>
                  </Dropdown>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div
        className="continueButtonDiv"
        style={{ display: isMobileLayout ? "none" : "" }}
      >
        <CustomButton
          text={t("CONTINUE")}
          buttonType={"primary-btn"}
          padding={"10px 50px"}
          onClick={() => handleSubmit()}
          htmlType="submit"
          disabled={buttonDisable}
        />
      </div>
      <div style={{ display: isMobileLayout ? "" : "none" }}>
        <MobileAddressButton
          disabled={buttonDisable}
          showCancelButton={false}
          setShowAddAddressOption={""}
          setSelectedAddressItem={""}
          selectedAddressItem={""}
          payloadData={""}
          otherLabel={""}
          selectedSavedAddress={""}
          selectedCardData={selectedCardData}
          handleButtonClicked={handleButtonClicked}
        />
      </div>
      <ErrorModal
        icon={<InfoCircleOutlined style={{ color: "#FF4D4F", fontSize: "22px" }} />}
        title={`${t("REMOVING_ADDRESS_CONFIRMATION")}?`}
        desc={
          <Typography className="regular14Heading moreOption  dynamic-font-family">
            <TranslateText label={"CONFIRM_REMOVE_ADDRESS"}>Are you sure you want to remove this address</TranslateText>?
          </Typography>
        }
        showModal={showDeleteModal}
        primaryOnClick={() => handleDeleteButton()}
        secondaryOnClick={() => setShowDeleteModal(false)}
        primaryBtnText={t("CONFIRM_REMOVE")}
        secondaryBtnText={t("NO")}
        showSecondaryButton={true}
      />
    </>
  );
}
