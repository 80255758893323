export const CurrencyData = [
    {
        "currencyCode": "AED",
        "currencyName": "United Arab Emirates Dirham",
        "countryCode": "AE",
        "countryName": "United Arab Emirates",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/AE.svg",
        "locale": "ar-AE"
    },
    {
        "currencyCode": "AFN",
        "currencyName": "Afghan Afghani",
        "countryCode": "AF",
        "countryName": "Afghanistan",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/AF.svg",
        "locale": "fa-AF"
    },
    {
        "currencyCode": "ALL",
        "currencyName": "Albanian Lek",
        "countryCode": "AL",
        "countryName": "Albania",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/AL.svg",
        "locale": "sq-AL"
    },
    {
        "currencyCode": "DZD",
        "currencyName": "Algerian Dinar",
        "countryCode": "DZ",
        "countryName": "Algeria",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/DZ.svg",
        "locale": "ar-DZ"
    },
    {
        "currencyCode": "AOA",
        "currencyName": "Angolan Kwanza",
        "countryCode": "AO",
        "countryName": "Angola",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/AO.svg",
        "locale": "pt-AO"
    },
    {
        "currencyCode": "XCD",
        "currencyName": "East Caribbean Dollar",
        "countryCode": "AG",
        "countryName": "Antigua and Barbuda",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/AG.svg",
        "locale": "en-AG"
    },
    {
        "currencyCode": "EUR",
        "currencyName": "Euro",
        "countryCode": "AT",
        "countryName": "Austria",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/AT.svg",
        "locale": "de-AT"
    },
    {
        "currencyCode": "AUD",
        "currencyName": "Australian Dollar",
        "countryCode": "AU",
        "countryName": "Australia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/AU.svg",
        "locale": "en-AU"
    },
    {
        "currencyCode": "AZN",
        "currencyName": "Azerbaijani Manat",
        "countryCode": "AZ",
        "countryName": "Azerbaijan",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/AZ.svg",
        "locale": "az-AZ"
    },
    {
        "currencyCode": "BSD",
        "currencyName": "Bahamian Dollar",
        "countryCode": "BS",
        "countryName": "Bahamas",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/BS.svg",
        "locale": "en-BS"
    },
    {
        "currencyCode": "BHD",
        "currencyName": "Bahraini Dinar",
        "countryCode": "BH",
        "countryName": "Bahrain",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/BH.svg",
        "locale": "ar-BH"
    },
    {
        "currencyCode": "BDT",
        "currencyName": "Bangladeshi Taka",
        "countryCode": "BD",
        "countryName": "Bangladesh",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/BD.svg",
        "locale": "bn-BD"
    },
    {
        "currencyCode": "BIF",
        "currencyName": "Burundian Franc",
        "countryCode": "BI",
        "countryName": "Burundi",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/BI.svg",
        "locale": "fr-BI"
    },
    {
        "currencyCode": "BMD",
        "currencyName": "Bermudian Dollar",
        "countryCode": "BM",
        "countryName": "Bermuda",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/BM.svg",
        "locale": "en-BM"
    },
    {
        "currencyCode": "BTN",
        "currencyName": "Bhutanese Ngultrum",
        "countryCode": "BT",
        "countryName": "Bhutan",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/BT.svg",
        "locale": "dz-BT"
    },
    {
        "currencyCode": "BOB",
        "currencyName": "Bolivian Boliviano",
        "countryCode": "BO",
        "countryName": "Bolivia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/BO.svg",
        "locale": "es-BO"
    },
    {
        "currencyCode": "BAM",
        "currencyName": "Bosnia-Herzegovina Convertible Mark",
        "countryCode": "BA",
        "countryName": "Bosnia and Herzegovina",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/BA.svg",
        "locale": "bs-BA"
    },
    {
        "currencyCode": "BRL",
        "currencyName": "Brazilian Real",
        "countryCode": "BR",
        "countryName": "Brazil",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/BR.svg",
        "locale": "pt-BR"
    },
    {
        "currencyCode": "GBP",
        "currencyName": "British Pound Sterling",
        "countryCode": "GB",
        "countryName": "United Kingdom",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/GB.svg",
        "locale": "en-GB"
    },
    {
        "currencyCode": "BWP",
        "currencyName": "Botswana Pula",
        "countryCode": "BW",
        "countryName": "Botswana",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/BW.svg",
        "locale": "en-BW"
    },
    {
        "currencyCode": "BYN",
        "currencyName": "Belarusian Ruble",
        "countryCode": "BY",
        "countryName": "Belarus",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/BY.svg",
        "locale": "be-BY"
    },
    {
        "currencyCode": "BZD",
        "currencyName": "Belize Dollar",
        "countryCode": "BZ",
        "countryName": "Belize",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/BZ.svg",
        "locale": "en-BZ"
    },
    {
        "currencyCode": "CDF",
        "currencyName": "Congolese Franc",
        "countryCode": "CD",
        "countryName": "Democratic Republic of the Congo",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/CD.svg",
        "locale": "fr-CD"
    },
    {
        "currencyCode": "CAD",
        "currencyName": "Canadian Dollar",
        "countryCode": "CA",
        "countryName": "Canada",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/CA.svg",
        "locale": "en-CA"
    },
    {
        "currencyCode": "KYD",
        "currencyName": "Cayman Islands Dollar",
        "countryCode": "KY",
        "countryName": "Cayman Islands",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/KY.svg",
        "locale": "en-KY"
    },
    {
        "currencyCode": "CLP",
        "currencyName": "Chilean Peso",
        "countryCode": "CL",
        "countryName": "Chile",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/CL.svg",
        "locale": "es-CL"
    },
    {
        "currencyCode": "CNY",
        "currencyName": "Chinese Yuan",
        "countryCode": "CN",
        "countryName": "China",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/CN.svg",
        "locale": "zh-CN"
    },
    {
        "currencyCode": "COP",
        "currencyName": "Colombian Peso",
        "countryCode": "CO",
        "countryName": "Colombia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/CO.svg",
        "locale": "es-CO"
    },
    {
        "currencyCode": "CRC",
        "currencyName": "Costa Rican Colón",
        "countryCode": "CR",
        "countryName": "Costa Rica",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/CR.svg",
        "locale": "es-CR"
    },
    {
        "currencyCode": "HRK",
        "currencyName": "Croatian Kuna",
        "countryCode": "HR",
        "countryName": "Croatia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/HR.svg",
        "locale": "hr-HR"
    },
    {
        "currencyCode": "CUP",
        "currencyName": "Cuban Peso",
        "countryCode": "CU",
        "countryName": "Cuba",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/CU.svg",
        "locale": "es-CU"
    },
    {
        "currencyCode": "CYP",
        "currencyName": "Cypriot Pound",
        "countryCode": "CY",
        "countryName": "Cyprus",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/CY.svg",
        "locale": "el-CY"
    },
    {
        "currencyCode": "CZK",
        "currencyName": "Czech Koruna",
        "countryCode": "CZ",
        "countryName": "Czech Republic",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/CZ.svg",
        "locale": "cs-CZ"
    },
    {
        "currencyCode": "DKK",
        "currencyName": "Danish Krone",
        "countryCode": "DK",
        "countryName": "Denmark",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/DK.svg",
        "locale": "da-DK"
    },
    {
        "currencyCode": "DJF",
        "currencyName": "Djiboutian Franc",
        "countryCode": "DJ",
        "countryName": "Djibouti",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/DJ.svg",
        "locale": "fr-DJ"
    },
    {
        "currencyCode": "DOP",
        "currencyName": "Dominican Peso",
        "countryCode": "DO",
        "countryName": "Dominican Republic",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/DO.svg",
        "locale": "es-DO"
    },
    {
        "currencyCode": "EGP",
        "currencyName": "Egyptian Pound",
        "countryCode": "EG",
        "countryName": "Egypt",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/EG.svg",
        "locale": "ar-EG"
    },
    {
        "currencyCode": "ERN",
        "currencyName": "Eritrean Nakfa",
        "countryCode": "ER",
        "countryName": "Eritrea",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/ER.svg",
        "locale": "ti-ER"
    },
    {
        "currencyCode": "EEK",
        "currencyName": "Estonian Kroon",
        "countryCode": "EE",
        "countryName": "Estonia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/EE.svg",
        "locale": "et-EE"
    },
    {
        "currencyCode": "ETB",
        "currencyName": "Ethiopian Birr",
        "countryCode": "ET",
        "countryName": "Ethiopia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/ET.svg",
        "locale": "am-ET"
    },
    {
        "currencyCode": "FJD",
        "currencyName": "Fijian Dollar",
        "countryCode": "FJ",
        "countryName": "Fiji",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/FJ.svg",
        "locale": "en-FJ"
    },
    {
        "currencyCode": "EUR",
        "currencyName": "Euro",
        "countryCode": "FI",
        "countryName": "Finland",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/FI.svg",
        "locale": "fi-FI"
    },
    {
        "currencyCode": "FKP",
        "currencyName": "Falkland Islands Pound",
        "countryCode": "FK",
        "countryName": "Falkland Islands",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/FK.svg",
        "locale": "en-FK"
    },
    {
        "currencyCode": "FRF",
        "currencyName": "French Franc",
        "countryCode": "FR",
        "countryName": "France",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/FR.svg",
        "locale": "fr-FR"
    },
    {
        "currencyCode": "GMD",
        "currencyName": "Gambian Dalasi",
        "countryCode": "GM",
        "countryName": "Gambia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/GM.svg",
        "locale": "en-GM"
    },
    {
        "currencyCode": "GEL",
        "currencyName": "Georgian Lari",
        "countryCode": "GE",
        "countryName": "Georgia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/GE.svg",
        "locale": "ka-GE"
    },
    {
        "currencyCode": "GHS",
        "currencyName": "Ghanaian Cedi",
        "countryCode": "GH",
        "countryName": "Ghana",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/GH.svg",
        "locale": "en-GH"
    },
    {
        "currencyCode": "GIP",
        "currencyName": "Gibraltar Pound",
        "countryCode": "GI",
        "countryName": "Gibraltar",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/GI.svg",
        "locale": "en-GI"
    },
    {
        "currencyCode": "GTQ",
        "currencyName": "Guatemalan Quetzal",
        "countryCode": "GT",
        "countryName": "Guatemala",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/GT.svg",
        "locale": "es-GT"
    },
    {
        "currencyCode": "GNF",
        "currencyName": "Guinean Franc",
        "countryCode": "GN",
        "countryName": "Guinea",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/GN.svg",
        "locale": "fr-GN"
    },
    {
        "currencyCode": "GYD",
        "currencyName": "Guyanese Dollar",
        "countryCode": "GY",
        "countryName": "Guyana",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/GY.svg",
        "locale": "en-GY"
    },
    {
        "currencyCode": "HKD",
        "currencyName": "Hong Kong Dollar",
        "countryCode": "HK",
        "countryName": "Hong Kong",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/HK.svg",
        "locale": "zh-HK"
    },
    {
        "currencyCode": "HNL",
        "currencyName": "Honduran Lempira",
        "countryCode": "HN",
        "countryName": "Honduras",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/HN.svg",
        "locale": "es-HN"
    },
    {
        "currencyCode": "HRK",
        "currencyName": "Croatian Kuna",
        "countryCode": "HR",
        "countryName": "Croatia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/HR.svg",
        "locale": "hr-HR"
    },
    {
        "currencyCode": "HUF",
        "currencyName": "Hungarian Forint",
        "countryCode": "HU",
        "countryName": "Hungary",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/HU.svg",
        "locale": "hu-HU"
    },
    {
        "currencyCode": "IDR",
        "currencyName": "Indonesian Rupiah",
        "countryCode": "ID",
        "countryName": "Indonesia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/ID.svg",
        "locale": "id-ID"
    },
    {
        "currencyCode": "INR",
        "currencyName": "Indian Rupee",
        "countryCode": "IN",
        "countryName": "India",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/IN.svg",
        "locale": "en-IN"
    },
    {
        "currencyCode": "IRR",
        "currencyName": "Iranian Rial",
        "countryCode": "IR",
        "countryName": "Iran",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/IR.svg",
        "locale": "fa-IR"
    },
    {
        "currencyCode": "IQD",
        "currencyName": "Iraqi Dinar",
        "countryCode": "IQ",
        "countryName": "Iraq",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/IQ.svg",
        "locale": "ar-IQ"
    },
    {
        "currencyCode": "ISK",
        "currencyName": "Icelandic Króna",
        "countryCode": "IS",
        "countryName": "Iceland",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/IS.svg",
        "locale": "is-IS"
    },
    {
        "currencyCode": "JMD",
        "currencyName": "Jamaican Dollar",
        "countryCode": "JM",
        "countryName": "Jamaica",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/JM.svg",
        "locale": "en-JM"
    },
    {
        "currencyCode": "JPY",
        "currencyName": "Japanese Yen",
        "countryCode": "JP",
        "countryName": "Japan",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/JP.svg",
        "locale": "ja-JP"
    },
    {
        "currencyCode": "JOD",
        "currencyName": "Jordanian Dinar",
        "countryCode": "JO",
        "countryName": "Jordan",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/JO.svg",
        "locale": "ar-JO"
    },
    {
        "currencyCode": "KZT",
        "currencyName": "Kazakhstani Tenge",
        "countryCode": "KZ",
        "countryName": "Kazakhstan",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/KZ.svg",
        "locale": "kk-KZ"
    },
    {
        "currencyCode": "KES",
        "currencyName": "Kenyan Shilling",
        "countryCode": "KE",
        "countryName": "Kenya",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/KE.svg",
        "locale": "en-KE"
    },
    {
        "currencyCode": "KGS",
        "currencyName": "Kyrgyzstani Som",
        "countryCode": "KG",
        "countryName": "Kyrgyzstan",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/KG.svg",
        "locale": "ky-KG"
    },
    {
        "currencyCode": "KHR",
        "currencyName": "Cambodian Riel",
        "countryCode": "KH",
        "countryName": "Cambodia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/KH.svg",
        "locale": "km-KH"
    },
    {
        "currencyCode": "KPW",
        "currencyName": "North Korean Won",
        "countryCode": "KP",
        "countryName": "North Korea",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/KP.svg",
        "locale": "ko-KP"
    },
    {
        "currencyCode": "KRW",
        "currencyName": "South Korean Won",
        "countryCode": "KR",
        "countryName": "South Korea",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/KR.svg",
        "locale": "ko-KR"
    },
    {
        "currencyCode": "KWD",
        "currencyName": "Kuwaiti Dinar",
        "countryCode": "KW",
        "countryName": "Kuwait",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/KW.svg",
        "locale": "ar-KW"
    },
    {
        "currencyCode": "KYD",
        "currencyName": "Cayman Islands Dollar",
        "countryCode": "KY",
        "countryName": "Cayman Islands",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/KY.svg",
        "locale": "en-KY"
    },
    {
        "currencyCode": "LAK",
        "currencyName": "Lao Kip",
        "countryCode": "LA",
        "countryName": "Laos",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/LA.svg",
        "locale": "lo-LA"
    },
    {
        "currencyCode": "LBP",
        "currencyName": "Lebanese Pound",
        "countryCode": "LB",
        "countryName": "Lebanon",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/LB.svg",
        "locale": "ar-LB"
    },
    {
        "currencyCode": "LSL",
        "currencyName": "Lesotho Loti",
        "countryCode": "LS",
        "countryName": "Lesotho",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/LS.svg",
        "locale": "st-LS"
    },
    {
        "currencyCode": "LRD",
        "currencyName": "Liberian Dollar",
        "countryCode": "LR",
        "countryName": "Liberia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/LR.svg",
        "locale": "en-LR"
    },
    {
        "currencyCode": "LYD",
        "currencyName": "Libyan Dinar",
        "countryCode": "LY",
        "countryName": "Libya",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/LY.svg",
        "locale": "ar-LY"
    },
    {
        "currencyCode": "MDL",
        "currencyName": "Moldovan Leu",
        "countryCode": "MD",
        "countryName": "Moldova",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/MD.svg",
        "locale": "ro-MD"
    },
    {
        "currencyCode": "MGA",
        "currencyName": "Malagasy Ariary",
        "countryCode": "MG",
        "countryName": "Madagascar",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/MG.svg",
        "locale": "mg-MG"
    },
    {
        "currencyCode": "MKD",
        "currencyName": "Macedonian Denar",
        "countryCode": "MK",
        "countryName": "North Macedonia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/MK.svg",
        "locale": "mk-MK"
    },
    {
        "currencyCode": "MMK",
        "currencyName": "Myanmar Kyat",
        "countryCode": "MM",
        "countryName": "Myanmar",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/MM.svg",
        "locale": "my-MM"
    },
    {
        "currencyCode": "MNT",
        "currencyName": "Mongolian Tögrög",
        "countryCode": "MN",
        "countryName": "Mongolia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/MN.svg",
        "locale": "mn-MN"
    },
    {
        "currencyCode": "MRU",
        "currencyName": "Mauritanian Ouguiya",
        "countryCode": "MR",
        "countryName": "Mauritania",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/MR.svg",
        "locale": "ar-MR"
    },
    {
        "currencyCode": "MUR",
        "currencyName": "Mauritian Rupee",
        "countryCode": "MU",
        "countryName": "Mauritius",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/MU.svg",
        "locale": "en-MU"
    },
    {
        "currencyCode": "MXN",
        "currencyName": "Mexican Peso",
        "countryCode": "MX",
        "countryName": "Mexico",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/MX.svg",
        "locale": "es-MX"
    },
    {
        "currencyCode": "MYR",
        "currencyName": "Malaysian Ringgit",
        "countryCode": "MY",
        "countryName": "Malaysia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/MY.svg",
        "locale": "ms-MY"
    },
    {
        "currencyCode": "MZN",
        "currencyName": "Mozambican Metical",
        "countryCode": "MZ",
        "countryName": "Mozambique",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/MZ.svg",
        "locale": "pt-MZ"
    },
    {
        "currencyCode": "NAD",
        "currencyName": "Namibian Dollar",
        "countryCode": "NA",
        "countryName": "Namibia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/NA.svg",
        "locale": "en-NA"
    },
    {
        "currencyCode": "NGN",
        "currencyName": "Nigerian Naira",
        "countryCode": "NG",
        "countryName": "Nigeria",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/NG.svg",
        "locale": "en-NG"
    },
    {
        "currencyCode": "NIO",
        "currencyName": "Nicaraguan Córdoba",
        "countryCode": "NI",
        "countryName": "Nicaragua",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/NI.svg",
        "locale": "es-NI"
    },
    {
        "currencyCode": "NOK",
        "currencyName": "Norwegian Krone",
        "countryCode": "NO",
        "countryName": "Norway",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/NO.svg",
        "locale": "no-NO"
    },
    {
        "currencyCode": "NPR",
        "currencyName": "Nepalese Rupee",
        "countryCode": "NP",
        "countryName": "Nepal",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/NP.svg",
        "locale": "ne-NP"
    },
    {
        "currencyCode": "NZD",
        "currencyName": "New Zealand Dollar",
        "countryCode": "NZ",
        "countryName": "New Zealand",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/NZ.svg",
        "locale": "en-NZ"
    },
    {
        "currencyCode": "OMR",
        "currencyName": "Omani Rial",
        "countryCode": "OM",
        "countryName": "Oman",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/OM.svg",
        "locale": "ar-OM"
    },
    {
        "currencyCode": "PAB",
        "currencyName": "Panamanian Balboa",
        "countryCode": "PA",
        "countryName": "Panama",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/PA.svg",
        "locale": "es-PA"
    },
    {
        "currencyCode": "PEN",
        "currencyName": "Peruvian Sol",
        "countryCode": "PE",
        "countryName": "Peru",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/PE.svg",
        "locale": "es-PE"
    },
    {
        "currencyCode": "PGK",
        "currencyName": "Papua New Guinean Kina",
        "countryCode": "PG",
        "countryName": "Papua New Guinea",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/PG.svg",
        "locale": "en-PG"
    },
    {
        "currencyCode": "PHP",
        "currencyName": "Philippine Peso",
        "countryCode": "PH",
        "countryName": "Philippines",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/PH.svg",
        "locale": "fil-PH"
    },
    {
        "currencyCode": "PKR",
        "currencyName": "Pakistani Rupee",
        "countryCode": "PK",
        "countryName": "Pakistan",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/PK.svg",
        "locale": "ur-PK"
    },
    {
        "currencyCode": "PLN",
        "currencyName": "Polish Zloty",
        "countryCode": "PL",
        "countryName": "Poland",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/PL.svg",
        "locale": "pl-PL"
    },
    {
        "currencyCode": "QAR",
        "currencyName": "Qatari Rial",
        "countryCode": "QA",
        "countryName": "Qatar",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/QA.svg",
        "locale": "ar-QA"
    },
    {
        "currencyCode": "RON",
        "currencyName": "Romanian Leu",
        "countryCode": "RO",
        "countryName": "Romania",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/RO.svg",
        "locale": "ro-RO"
    },
    {
        "currencyCode": "RSD",
        "currencyName": "Serbian Dinar",
        "countryCode": "RS",
        "countryName": "Serbia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/RS.svg",
        "locale": "sr-RS"
    },
    {
        "currencyCode": "RUB",
        "currencyName": "Russian Ruble",
        "countryCode": "RU",
        "countryName": "Russia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/RU.svg",
        "locale": "ru-RU"
    },
    {
        "currencyCode": "RWF",
        "currencyName": "Rwandan Franc",
        "countryCode": "RW",
        "countryName": "Rwanda",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/RW.svg",
        "locale": "rw-RW"
    },
    {
        "currencyCode": "SAR",
        "currencyName": "Saudi Riyal",
        "countryCode": "SA",
        "countryName": "Saudi Arabia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/SA.svg",
        "locale": "ar-SA"
    },
    {
        "currencyCode": "SBD",
        "currencyName": "Solomon Islands Dollar",
        "countryCode": "SB",
        "countryName": "Solomon Islands",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/SB.svg",
        "locale": "en-SB"
    },
    {
        "currencyCode": "SCR",
        "currencyName": "Seychellois Rupee",
        "countryCode": "SC",
        "countryName": "Seychelles",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/SC.svg",
        "locale": "en-SC"
    },
    {
        "currencyCode": "SDG",
        "currencyName": "Sudanese Pound",
        "countryCode": "SD",
        "countryName": "Sudan",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/SD.svg",
        "locale": "ar-SD"
    },
    {
        "currencyCode": "SEK",
        "currencyName": "Swedish Krona",
        "countryCode": "SE",
        "countryName": "Sweden",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/SE.svg",
        "locale": "sv-SE"
    },
    {
        "currencyCode": "SGD",
        "currencyName": "Singapore Dollar",
        "countryCode": "SG",
        "countryName": "Singapore",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/SG.svg",
        "locale": "en-SG"
    },
    {
        "currencyCode": "SLL",
        "currencyName": "Sierra Leonean Leone",
        "countryCode": "SL",
        "countryName": "Sierra Leone",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/SL.svg",
        "locale": "en-SL"
    },
    {
        "currencyCode": "SOS",
        "currencyName": "Somali Shilling",
        "countryCode": "SO",
        "countryName": "Somalia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/SO.svg",
        "locale": "so-SO"
    },
    {
        "currencyCode": "SRD",
        "currencyName": "Surinamese Dollar",
        "countryCode": "SR",
        "countryName": "Suriname",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/SR.svg",
        "locale": "nl-SR"
    },
    {
        "currencyCode": "SYP",
        "currencyName": "Syrian Pound",
        "countryCode": "SY",
        "countryName": "Syria",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/SY.svg",
        "locale": "ar-SY"
    },
    {
        "currencyCode": "SZL",
        "currencyName": "Swazi Lilangeni",
        "countryCode": "SZ",
        "countryName": "Eswatini",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/SZ.svg",
        "locale": "en-SZ"
    },
    {
        "currencyCode": "THB",
        "currencyName": "Thai Baht",
        "countryCode": "TH",
        "countryName": "Thailand",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/TH.svg",
        "locale": "th-TH"
    },
    {
        "currencyCode": "TJS",
        "currencyName": "Tajikistani Somoni",
        "countryCode": "TJ",
        "countryName": "Tajikistan",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/TJ.svg",
        "locale": "tg-TJ"
    },
    {
        "currencyCode": "TMT",
        "currencyName": "Turkmenistani Manat",
        "countryCode": "TM",
        "countryName": "Turkmenistan",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/TM.svg",
        "locale": "tk-TM"
    },
    {
        "currencyCode": "TND",
        "currencyName": "Tunisian Dinar",
        "countryCode": "TN",
        "countryName": "Tunisia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/TN.svg",
        "locale": "ar-TN"
    },
    {
        "currencyCode": "TOP",
        "currencyName": "Tongan Paʻanga",
        "countryCode": "TO",
        "countryName": "Tonga",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/TO.svg",
        "locale": "to-TO"
    },
    {
        "currencyCode": "TRY",
        "currencyName": "Turkish Lira",
        "countryCode": "TR",
        "countryName": "Turkey",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/TR.svg",
        "locale": "tr-TR"
    },
    {
        "currencyCode": "TTD",
        "currencyName": "Trinidad and Tobago Dollar",
        "countryCode": "TT",
        "countryName": "Trinidad and Tobago",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/TT.svg",
        "locale": "en-TT"
    },
    {
        "currencyCode": "TWD",
        "currencyName": "New Taiwan Dollar",
        "countryCode": "TW",
        "countryName": "Taiwan",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/TW.svg",
        "locale": "zh-TW"
    },
    {
        "currencyCode": "TZS",
        "currencyName": "Tanzanian Shilling",
        "countryCode": "TZ",
        "countryName": "Tanzania",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/TZ.svg",
        "locale": "sw-TZ"
    },
    {
        "currencyCode": "UAH",
        "currencyName": "Ukrainian Hryvnia",
        "countryCode": "UA",
        "countryName": "Ukraine",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/UA.svg",
        "locale": "uk-UA"
    },
    {
        "currencyCode": "UGX",
        "currencyName": "Ugandan Shilling",
        "countryCode": "UG",
        "countryName": "Uganda",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/UG.svg",
        "locale": "en-UG"
    },
    {
        "currencyCode": "USD",
        "currencyName": "United States Dollar",
        "countryCode": "US",
        "countryName": "United States",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg",
        "locale": "en-US"
    },
    {
        "currencyCode": "UYU",
        "currencyName": "Uruguayan Peso",
        "countryCode": "UY",
        "countryName": "Uruguay",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/UY.svg",
        "locale": "es-UY"
    },
    {
        "currencyCode": "UZS",
        "currencyName": "Uzbekistani Som",
        "countryCode": "UZ",
        "countryName": "Uzbekistan",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/UZ.svg",
        "locale": "uz-UZ"
    },
    {
        "currencyCode": "VEF",
        "currencyName": "Venezuelan Bolívar",
        "countryCode": "VE",
        "countryName": "Venezuela",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/VE.svg",
        "locale": "es-VE"
    },
    {
        "currencyCode": "VND",
        "currencyName": "Vietnamese Dong",
        "countryCode": "VN",
        "countryName": "Vietnam",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/VN.svg",
        "locale": "vi-VN"
    },
    {
        "currencyCode": "VUV",
        "currencyName": "Vanuatu Vatu",
        "countryCode": "VU",
        "countryName": "Vanuatu",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/VU.svg",
        "locale": "en-VU"
    },
    {
        "currencyCode": "WST",
        "currencyName": "Samoan Tala",
        "countryCode": "WS",
        "countryName": "Samoa",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/WS.svg",
        "locale": "sm-WS"
    },
    {
        "currencyCode": "XAF",
        "currencyName": "Central African CFA Franc",
        "countryCode": "CM",
        "countryName": "Cameroon",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/CM.svg",
        "locale": "fr-CM"
    },
    {
        "currencyCode": "XCD",
        "currencyName": "East Caribbean Dollar",
        "countryCode": "AG",
        "countryName": "Antigua and Barbuda",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/AG.svg",
        "locale": "en-AG"
    },
    {
        "currencyCode": "XOF",
        "currencyName": "West African CFA Franc",
        "countryCode": "BJ",
        "countryName": "Benin",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/BJ.svg",
        "locale": "fr-BJ"
    },
    {
        "currencyCode": "XPF",
        "currencyName": "CFP Franc",
        "countryCode": "PF",
        "countryName": "French Polynesia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/PF.svg",
        "locale": "fr-PF"
    },
    {
        "currencyCode": "YER",
        "currencyName": "Yemeni Rial",
        "countryCode": "YE",
        "countryName": "Yemen",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/YE.svg",
        "locale": "ar-YE"
    },
    {
        "currencyCode": "ZAR",
        "currencyName": "South African Rand",
        "countryCode": "ZA",
        "countryName": "South Africa",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/ZA.svg",
        "locale": "en-ZA"
    },
    {
        "currencyCode": "ZMW",
        "currencyName": "Zambian Kwacha",
        "countryCode": "ZM",
        "countryName": "Zambia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/ZM.svg",
        "locale": "en-ZM"
    },
    {
        "currencyCode": "ZWL",
        "currencyName": "Zimbabwean Dollar",
        "countryCode": "ZW",
        "countryName": "Zimbabwe",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/ZW.svg",
        "locale": "en-ZW"
    },
    {
        "currencyCode": "BOV",
        "currencyName": "Bolivian Mvdol",
        "countryCode": "BO",
        "countryName": "Bolivia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/BO.svg",
        "locale": "es-BO"
    },
    {
        "currencyCode": "CVE",
        "currencyName": "Cape Verde Escudo",
        "countryCode": "CV",
        "countryName": "Cape Verde",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/CV.svg",
        "locale": "pt-CV"
    },
    {
        "currencyCode": "STN",
        "currencyName": "São Tomé and Príncipe Dobra",
        "countryCode": "ST",
        "countryName": "São Tomé and Príncipe",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/ST.svg",
        "locale": "pt-ST"
    },
    {
        "currencyCode": "HTG",
        "currencyName": "Haitian Gourde",
        "countryCode": "HT",
        "countryName": "Haiti",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/HT.svg",
        "locale": "fr-HT"
    },
    {
        "currencyCode": "BND",
        "currencyName": "Brunei Dollar",
        "countryCode": "BN",
        "countryName": "Brunei",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/BN.svg",
        "locale": "ms-BN"
    },
    {
        "currencyCode": "FOK",
        "currencyName": "Faroese Króna",
        "countryCode": "FO",
        "countryName": "Faroe Islands",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/FO.svg",
        "locale": "fo-FO"
    },
    {
        "currencyCode": "ILS",
        "currencyName": "Israeli New Shekel",
        "countryCode": "IL",
        "countryName": "Israel",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/IL.svg",
        "locale": "he-IL"
    },
    {
        "currencyCode": "SLE",
        "currencyName": "Sierra Leonean Leone",
        "countryCode": "SL",
        "countryName": "Sierra Leone",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/SL.svg",
        "locale": "en-SL"
    },
    {
        "currencyCode": "VED",
        "currencyName": "Venezuelan Bolívar Soberano",
        "countryCode": "VE",
        "countryName": "Venezuela",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/VE.svg",
        "locale": "es-VE"
    },
    {
        "currencyCode": "AMD",
        "currencyName": "Armenian Dram",
        "countryCode": "AM",
        "countryName": "Armenia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/AM.svg",
        "locale": "hy-AM"
    },
    {
        "currencyCode": "VES",
        "currencyName": "Venezuelan Bolívar",
        "countryCode": "VE",
        "countryName": "Venezuela",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/VE.svg",
        "locale": "es-VE"
    },
    {
        "currencyCode": "KID",
        "currencyName": "Kiribati Dollar",
        "countryCode": "KI",
        "countryName": "Kiribati",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/KI.svg",
        "locale": "en-KI"
    },
    {
        "currencyCode": "ARS",
        "currencyName": "Argentine Peso",
        "countryCode": "AR",
        "countryName": "Argentina",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/AR.svg",
        "locale": "es-AR"
    },
    {
        "currencyCode": "SSP",
        "currencyName": "South Sudanese Pound",
        "countryCode": "SS",
        "countryName": "South Sudan",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/SS.svg",
        "locale": "en-SS"
    },
    {
        "currencyCode": "MWK",
        "currencyName": "Malawian Kwacha",
        "countryCode": "MW",
        "countryName": "Malawi",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/MW.svg",
        "locale": "en-MW"
    },
    {
        "currencyCode": "LKR",
        "currencyName": "Sri Lankan Rupee",
        "countryCode": "LK",
        "countryName": "Sri Lanka",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/LK.svg",
        "locale": "si-LK"
    },
    {
        "currencyCode": "MOP",
        "currencyName": "Macanese Pataca",
        "countryCode": "MO",
        "countryName": "Macau",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/MO.svg",
        "locale": "zh-MO"
    },
    {
        "currencyCode": "ANG",
        "currencyName": "Netherlands Antillean Guilder",
        "countryCode": "AN",
        "countryName": "Netherlands Antilles",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/AN.svg",
        "locale": "nl-AN"
    },
    {
        "currencyCode": "SVC",
        "currencyName": "Salvadoran Colón",
        "countryCode": "SV",
        "countryName": "El Salvador",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/SV.svg",
        "locale": "es-SV"
    },
    {
        "currencyCode": "PYG",
        "currencyName": "Paraguayan Guaraní",
        "countryCode": "PY",
        "countryName": "Paraguay",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/PY.svg",
        "locale": "es-PY"
    },
    {
        "currencyCode": "SHP",
        "currencyName": "Saint Helena Pound",
        "countryCode": "SH",
        "countryName": "Saint Helena",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/SH.svg",
        "locale": "en-SH"
    },
    {
        "currencyCode": "COU",
        "currencyName": "Unidad de Valor Real",
        "countryCode": "CO",
        "countryName": "Colombia",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/CO.svg",
        "locale": "es-CO"
    },
    {
        "currencyCode": "XDR",
        "currencyName": "Special Drawing Rights",
        "countryCode": "IMF",
        "countryName": "International Monetary Fund",
        "flag": "",
        "locale": ""
    },
    {
        "currencyCode": "MVR",
        "currencyName": "Maldivian Rufiyaa",
        "countryCode": "MV",
        "countryName": "Maldives",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/MV.svg",
        "locale": "dv-MV"
    },
    {
        "currencyCode": "KMF",
        "currencyName": "Comorian Franc",
        "countryCode": "KM",
        "countryName": "Comoros",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/KM.svg",
        "locale": "ar-KM"
    },
    {
        "currencyCode": "GGP",
        "currencyName": "Guernsey Pound",
        "countryCode": "GG",
        "countryName": "Guernsey",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/GG.svg",
        "locale": "en-GG"
    },
    {
        "currencyCode": "CHF",
        "currencyName": "Swiss Franc",
        "countryCode": "CH",
        "countryName": "Switzerland",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/CH.svg",
        "locale": "de-CH"
    },
    {
        "currencyCode": "UYI",
        "currencyName": "Uruguayan Peso en Unidades Indexadas",
        "countryCode": "UY",
        "countryName": "Uruguay",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/UY.svg",
        "locale": "es-UY"
    },
    {
        "currencyCode": "IMP",
        "currencyName": "Isle of Man Pound",
        "countryCode": "IM",
        "countryName": "Isle of Man",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/IM.svg",
        "locale": "en-IM"
    },
    {
        "currencyCode": "MXV",
        "currencyName": "Mexican Unidad de Inversion (UDI)",
        "countryCode": "MX",
        "countryName": "Mexico",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/MX.svg",
        "locale": "es-MX"
    },
    {
        "currencyCode": "CHE",
        "currencyName": "WIR Euro",
        "countryCode": "CH",
        "countryName": "Switzerland",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/CH.svg",
        "locale": "de-CH"
    },
    {
        "currencyCode": "AWG",
        "currencyName": "Aruban Florin",
        "countryCode": "AW",
        "countryName": "Aruba",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/AW.svg",
        "locale": "nl-AW"
    },
    {
        "currencyCode": "TVD",
        "currencyName": "Tuvaluan Dollar",
        "countryCode": "TV",
        "countryName": "Tuvalu",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/TV.svg",
        "locale": "en-TV"
    },
    {
        "currencyCode": "CLF",
        "currencyName": "Unidad de Fomento",
        "countryCode": "CL",
        "countryName": "Chile",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/CL.svg",
        "locale": "es-CL"
    },
    {
        "currencyCode": "MAD",
        "currencyName": "Moroccan Dirham",
        "countryCode": "MA",
        "countryName": "Morocco",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/MA.svg",
        "locale": "ar-MA"
    },
    {
        "currencyCode": "CUC",
        "currencyName": "Cuban Convertible Peso",
        "countryCode": "CU",
        "countryName": "Cuba",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/CU.svg",
        "locale": "es-CU"
    },
    {
        "currencyCode": "CHW",
        "currencyName": "WIR Franc",
        "countryCode": "CH",
        "countryName": "Switzerland",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/CH.svg",
        "locale": "de-CH"
    },
    {
        "currencyCode": "BBD",
        "currencyName": "Barbadian Dollar",
        "countryCode": "BB",
        "countryName": "Barbados",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/BB.svg",
        "locale": "en-BB"
    },
    {
        "currencyCode": "UYW",
        "currencyName": "Uruguayan Nominal Wage Index Unit",
        "countryCode": "UY",
        "countryName": "Uruguay",
        "flag": "https://purecatamphetamine.github.io/country-flag-icons/3x2/UY.svg",
        "locale": "es-UY"
    }
];