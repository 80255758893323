import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import TranslateText from "../../resuableComponents/TranslateText";
import "./paymentButton.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectActivePaymentMethod,
  selectButtonError,
  selectButtonLoading,
  selectPaymentMethodInfo,
  selectPopWindowIframeUpiIntent,
  toggleButtonError,
} from "../../../slices/PaymentButtonSlice";
import {
  resetErrorMsg,
  selectCurrencyCode,
  selectCurrencySymbol,
  selectToatalAmount,
  selectToken,
} from "../../../slices/PaymentSessionDetailsSlice";
import CustomButton from "../../resuableComponents/CustomButton";
import { selectIsMobileLayout } from "../../../slices/MobileLayoutSlice";
import { isEmpty, numberToCurrency, getStroage, updateStroage, handleRoutingOnPaymentStatus, getPopDimensionDetails, isFireFox, iframePopUpContainerStyle, iframeLoadingComponent, getNetAmount } from "../../../Utils/utils";
import {
  handleUpiError,
  intentAppName,
  resetUpiStatus,
  selectShowUpiVpaTimer,
  selectUpiVpa,
  setVpaValidationDetails,
  toggleUpiVpaTimer,
  validUpiId,
} from "../../../slices/UpiSlice";
import { buildPaymentRequest } from "../../../Utils/paymentUtils";
import {
  initiatePayment,
  resetPaymentInitiation,
  resetPaymentInitiationLoading,
  showFailedModal,
} from "../../../slices/PaymentInitiationSlice";
import { selectSelectedIntrument } from "../../../slices/SavedInstrumentSlice";
import PaymentResponseHandler from "../../../Utils/paymentResponseHandler";
import Loading from "../loadingScreen/Loading";
import {
  cardCvcData,
  cardExpiryDateData,
  cardHolderNameData,
  cardNumberData,
  cardbrandName,
  disableStateOfButton,
} from "../../../slices/CardSlice";
import ErrorModal from "../modals/errorModal/ErrorModal";
import { InfoCircleOutlined } from "@ant-design/icons";
import {
  getActiveStatus,
  resetFinalStatusResponse,
} from "../../../slices/finalStatusSlice";
import UpiTimerModal from "../paymentOptions/Upi/UpiTimerModal";
import {
  durationValue,
  emiButton,
  emiCodeValue,
} from "../../../slices/EmiSlice";
import { paymentInitiatedEvent } from "../../../Utils/uiAnalytics";
import getSymbolFromCurrency from "currency-symbol-map";
import { handleTotalPaymentSummaryError } from "../../../slices/HandleAdditionalComponentsSlice";
import axios from "axios";
import { getTransactionStatus, resetTransactionStatus, selectTransactionData } from "../../../slices/TransactionIdSlice";
import PaymentContext from "../../../context/payment.context";

const PyamentButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { isDccSelected } = useContext(PaymentContext);
  const isIframe = window.self !== window.top;
  const popWindowIframeRef = useRef();
  const iframeWindowStatusInterval = useRef();
  const [isIframePopUpBlocked, setIsIframePopUpBlocked] = useState(false);
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));
  const token = useSelector((state) => selectToken(state));
  const buttonError = useSelector((state) => selectButtonError(state));
  const buttonLoading = useSelector((state) => selectButtonLoading(state));
  const currencyCode = useSelector((state) => selectCurrencyCode(state));
  const amount = useSelector((state) => selectToatalAmount(state));
  const paymentSuccessData = useSelector((state) => selectTransactionData(state));
  const selectedInstrument = useSelector((state) => selectSelectedIntrument(state));
  const activePaymentMethod = useSelector((state) => selectActivePaymentMethod(state));
  const buttonDisableState = useSelector((state) => disableStateOfButton(state));
  const upiVpa = useSelector((state) => selectUpiVpa(state));
  const cardNumber = useSelector((state) => cardNumberData(state));
  const cardHolderName = useSelector((state) => cardHolderNameData(state));
  const cardExpiryDate = useSelector((state) => cardExpiryDateData(state));
  const cardCvc = useSelector((state) => cardCvcData(state));
  const latestBrandName = useSelector((state) => cardbrandName(state));
  const selectedTabValue = useSelector((state) => state.emiCardDetails.selectedTabValue);
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);
  const sessionDetailsStatusReason = useSelector((state) => state.paymentSessionDetails.sessionDetailsStatusReason);
  const sampleToken = useSelector((state) => state.paymentSessionDetails.sampleToken);
  const paymentMethodInfo = useSelector((state) => selectPaymentMethodInfo(state));
  const paymentInitiationData = useSelector((state) => state.paymentInitiation.paymentInitiationData);
  const paymentInitiationError = useSelector((state) => state.paymentInitiation.paymentInitiationError);
  const isPaymentRejectedOrFailed = useSelector((state) => state.paymentInitiation.isPaymentRejectedOrFailed);
  const paymentInitiationLoading = useSelector((state) => state.paymentInitiation.paymentInitiationLoading);
  const failedModal = useSelector((state) => state.paymentInitiation.failedModal);
  const updatedShopperDetails = useSelector((state) => state.paymentSessionDetails.updatedShopperDetails);
  const [validUpiIdData, setValidUpiIdData] = useState();
  const [validUpiIdErrorMessage, setValidUpiIdErrorMessage] = useState();
  const [validUpiIdLoading, setValidUpiIdLoading] = useState();
  const showTotalPaymentSummaryField = useSelector((state) => state.additionalComponents.showTotalPaymentSummaryField);
  const totalPaymentSummaryValue = useSelector((state) => state.additionalComponents.totalPaymentSummaryValue);
  const emiPaymentButton = useSelector((state) => emiButton(state));
  const [showPaymentLoading, setShowPaymentLoading] = useState(false);
  const intervalRef = useRef(null);
  const showUpiVpaTimer = useSelector((state) => selectShowUpiVpaTimer(state));
  const activeStatusReason = useSelector((state) => state.finalStatus.activeStatusReason);
  const callerType = useSelector((state) => state.paymentSessionDetails.callerType);
  const duration = useSelector((state) => durationValue(state));
  const emiCode = useSelector((state) => emiCodeValue(state));
  const upiIntentName = useSelector((state) => intentAppName(state));
  const transactionId = getStroage("session", "t_id") || getStroage("session", "s_d")?.lastTransactionId;
  const isIphone = useSelector((state) => state.mobileLayout.isIphone);
  const popUpWindowIframeForUpiIntent = useSelector((state) => selectPopWindowIframeUpiIntent(state));
  const isDccQuotationSelected = useSelector((state) => state.cardDetails.isDccQuotationSelected);
  const dccQuotation = useSelector(state => state.cardDetails.dccQuotation);
  const dccMoney = dccQuotation?.dccQuotationDetails?.dccMoney?.amount;
  const issuerCurrencyDetails = useSelector(state => state.cardDetails.issuerCurrencyDetails);

  useEffect(() => {
    dispatch(resetPaymentInitiation());
    clearInterval(intervalRef.current);
  }, [activePaymentMethod]);
  useEffect(() => {
    if (paymentInitiationData === null) {
      stopSatusCall();
    }
  }, [paymentInitiationData]);

  // UPI -- starts
  // handleQrPay in UpiQr and handleIntentPay in UpiIntent
  const handleUpiValidation = async (payload) => {
    setValidUpiIdLoading(true);
    const baseUrl = process.env.REACT_APP_BASE_URL;
    try {
      const response = await axios.post(
        `${baseUrl}/platform/vpa-validation`,
        payload
      );
      setValidUpiIdLoading(false);
      setValidUpiIdData(response.data);
      dispatch(setVpaValidationDetails({ data: response.data }));
    } catch (error) {
      setValidUpiIdLoading(false);
      if (error?.response) {
        dispatch(setVpaValidationDetails(error?.response?.data));
        setValidUpiIdErrorMessage(error?.response?.data);
        return error?.response?.data;
      } else {
        dispatch(setVpaValidationDetails(error?.message));
        setValidUpiIdErrorMessage(error?.message);
        return error?.message;
      }
    }
  };
  const handleUpiPay = () => {
    const vpaPayload = {
      vpa: upiVpa,
      legalEntity: sessionDetails?.paymentDetails?.context?.legalEntity?.code,
      merchantId: sessionDetails?.merchantId,
      countryCode: "IN",
    };
    handleUpiValidation(vpaPayload);
  };
  useEffect(() => {
    if (!isEmpty(validUpiIdData)) {
      if (validUpiIdData?.vpaValid === true) {
        let paymentRequest = buildPaymentRequest(
          paymentMethodInfo?.instrumentTypeValue ||
          selectedInstrument?.instrumentTypeValue ||
          "upi/collect",
          updatedShopperDetails,
          sessionDetails
        );
        if (activePaymentMethod === "UpiSaved") {
          paymentRequest.payload.instrumentDetails.upi = {
            shopperVpa: selectedInstrument?.value,
          };
        } else {
          paymentRequest.payload.instrumentDetails.upi = {
            shopperVpa: upiVpa || selectedInstrument?.value,
          };
        }
        if (totalPaymentSummaryValue) {
          paymentRequest.payload.money = {
            amount: totalPaymentSummaryValue,
            currencyCode: sessionDetails?.paymentDetails?.money?.currencyCode,
          };
        }
        let token = paymentRequest?.token;
        let payload = paymentRequest?.payload;
        if (showTotalPaymentSummaryField && totalPaymentSummaryValue === "") {
          dispatch(handleTotalPaymentSummaryError("Please enter amount"));
          window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
        } else {
          dispatch(initiatePayment({ token, payload }));
          paymentInitiatedEvent(
            sessionDetails,
            "Upi",
            paymentMethodInfo?.instrumentTypeValue ||
            selectedInstrument?.instrumentTypeValue ||
            "UpiCollect",
            dispatch
          );
        }
      } else if (validUpiIdData?.vpaValid === false) {
        dispatch(toggleButtonError(true));
        dispatch(handleUpiError("Invalid UPI Id"));
      }
    } else if (!isEmpty(validUpiIdErrorMessage)) {
      let paymentRequest = buildPaymentRequest(
        paymentMethodInfo?.instrumentTypeValue ||
        selectedInstrument?.instrumentTypeValue ||
        "upi/collect",
        updatedShopperDetails,
        sessionDetails
      );
      if (activePaymentMethod === "UpiSaved") {
        paymentRequest.payload.instrumentDetails.upi = {
          shopperVpa: selectedInstrument?.value,
        };
      } else {
        paymentRequest.payload.instrumentDetails.upi = {
          shopperVpa: upiVpa || selectedInstrument?.value,
        };
      }
      if (totalPaymentSummaryValue) {
        paymentRequest.payload.money = {
          amount: totalPaymentSummaryValue,
          currencyCode: sessionDetails?.paymentDetails?.money?.currencyCode,
        };
      }
      let token = paymentRequest?.token;
      let payload = paymentRequest?.payload;
      if (showTotalPaymentSummaryField && totalPaymentSummaryValue === "") {
        dispatch(handleTotalPaymentSummaryError("Please enter amount"));
        window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
      } else {
        dispatch(initiatePayment({ token, payload }));
        paymentInitiatedEvent(
          sessionDetails,
          "Upi",
          paymentMethodInfo?.instrumentTypeValue ||
          selectedInstrument?.instrumentTypeValue ||
          "UpiCollect",
          dispatch
        );
      }
    }
  }, [validUpiIdData, validUpiIdErrorMessage]);
  const checkIframePopWindowStatus = () => {
    iframeWindowStatusInterval.current = setInterval(() => {
      if (popWindowIframeRef.current?.closed) {
        setShowPaymentLoading(false);
        dispatch(resetPaymentInitiation());
        dispatch(resetPaymentInitiationLoading())
        clearInterval(iframeWindowStatusInterval.current);
      }
    }, 1000);
  }
  useEffect(() => {
    if (paymentInitiationData && paymentInitiationData?.paymentMethod?.type === "Upi" || (paymentInitiationData && isIframe)) {
      let token = sessionDetails?.token;
      if (isIframe) checkIframePopWindowStatus();
      if (paymentInitiationData.paymentMethod?.brand === "UpiCollect") {
        dispatch(toggleUpiVpaTimer(true));
      }
      intervalRef.current = setInterval(() => {
        dispatch(getTransactionStatus({ token, transactionId }));
        // dispatch(getActiveStatus({ token }));
      }, 4000);
      return () => {
        clearInterval(intervalRef.current);
      };
    }
  }, [paymentInitiationData]);

  const getRedirectionResult = (redirectUrl) => {
    const parsedUrl = new URL(redirectUrl);
    const params = new URLSearchParams(parsedUrl.search);
    return params.get('redirectionResult');
  }

  const handlePostMessageEventsForIframe = () => {
    if (paymentSuccessData?.status === "FAILED" || paymentSuccessData?.status === "Rejected") {
      stopSatusCall();
      dispatch(showFailedModal(true));
      return
    }
    if (paymentSuccessData?.status === "PAID" || paymentSuccessData?.status === "PROCESSING") {
      let redirectUrl = paymentSuccessData?.actions[0]?.url;
      if (paymentInitiationData?.paymentMethod?.type === "Upi") {
        stopSatusCall();
        const message = {
          action: 'closeIframe',
          payload: { data: getRedirectionResult(redirectUrl) }
        };
        window.parent.postMessage(message, '*');
      } else {
        const parsedUrl = new URL(redirectUrl);
        const params = new URLSearchParams(parsedUrl.search);
        let redirectionUrl = params.get('redirectionResult');
        stopSatusCall();
        const message = {
          action: 'closeIframe',
          payload: { data: redirectionUrl }
        };
        window.parent.postMessage(message, '*');
      }
    } else {
      window.addEventListener('message', (e) => {
        let eventResponse = e?.data?.data;
        if (eventResponse?.redirectionResult) {
          stopSatusCall();
          const message = {
            action: 'closeIframe',
            payload: { data: eventResponse }
          };
          window.parent.postMessage(message, '*'); // send the data to sdk from where this iframe is triggered
        } else {
          handleRoutingOnPaymentStatus({
            paymentSuccessData,
            sessionDetails,
            stopSatusCall,
            dispatch,
            showFailedModal
          });
        }
      });
    }
  }
  useEffect(() => {
    if (!paymentSuccessData) return
    if (isIframe) {
      handlePostMessageEventsForIframe();
    } else {
      handleRoutingOnPaymentStatus({
        paymentSuccessData,
        sessionDetails,
        stopSatusCall,
        dispatch,
        showFailedModal
      });
    }
  }, [paymentSuccessData]);

  const closeIframePopups = () => {
    if (isIframe) {
      setShowPaymentLoading(false);
      popWindowIframeRef?.current?.close();
      if (!isEmpty(popUpWindowIframeForUpiIntent)) {
        popUpWindowIframeForUpiIntent?.close();
      }
    }
  }
  const stopSatusCall = () => {
    clearInterval(intervalRef.current);
    closeIframePopups();
    if (paymentInitiationData && paymentInitiationData?.paymentMethod?.brand === "UpiCollect") {
      dispatch(toggleUpiVpaTimer(false));
      dispatch(resetPaymentInitiation());
      dispatch(resetUpiStatus());
    }
  };
  const setPopupWindowForIframe = () => {
    const { width, height, left, top } = getPopDimensionDetails();
    popWindowIframeRef.current = window.open('', 'FormPopup', `width=${width},height=${height},top=${top},left=${left}`);
    const container = iframeLoadingComponent(popWindowIframeRef.current, `${t("LOADING")}...`);
    popWindowIframeRef.current.document.body.appendChild(container);
  }
  const handleWalletBNPLNetBankingPay = () => {
    let paymentRequest = buildPaymentRequest(
      selectedInstrument?.instrumentTypeValue,
      updatedShopperDetails,
      sessionDetails
    );
    if (totalPaymentSummaryValue) {
      paymentRequest.payload.money = {
        amount: totalPaymentSummaryValue,
        currencyCode: sessionDetails?.paymentDetails?.money?.currencyCode,
      };
    }
    let token = paymentRequest?.token;
    let payload = paymentRequest?.payload;
    if (showTotalPaymentSummaryField && totalPaymentSummaryValue === "") {
      dispatch(handleTotalPaymentSummaryError("Please enter amount"));
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
    } else {
      dispatch(initiatePayment({ token, payload }));
      paymentInitiatedEvent(
        sessionDetails,
        selectedInstrument?.type,
        selectedInstrument?.brand,
        dispatch
      );
      if (isIframe) {
        setPopupWindowForIframe()
      }
    }
  };
  const handleCardPay = () => {
    let paymentRequest = buildPaymentRequest(
      "card/meta",
      updatedShopperDetails,
      sessionDetails
    );
    if (sessionDetails?.context?.countryCode === "IN") {
      paymentRequest = buildPaymentRequest(
        "card/meta",
        updatedShopperDetails,
        sessionDetails
      );
      paymentRequest.payload.instrumentDetails.cardMeta = {
        brand: latestBrandName,
      };
      if (totalPaymentSummaryValue) {
        paymentRequest.payload.money = {
          amount: totalPaymentSummaryValue,
          currencyCode: sessionDetails?.paymentDetails?.money?.currencyCode,
        };
      }
    } else {
      const [expiry_month, expiry_year] = cardExpiryDate?.split("/");
      paymentRequest = buildPaymentRequest(
        "card/plain",
        updatedShopperDetails,
        sessionDetails
      );
      paymentRequest.payload.instrumentDetails.card = {
        number: cardNumber,
        expiry: `20${expiry_year}-${expiry_month}`,
        cvc: cardCvc,
        holderName: cardHolderName,
      };
      if (totalPaymentSummaryValue) {
        paymentRequest.payload.money = {
          amount: totalPaymentSummaryValue,
          currencyCode: sessionDetails?.paymentDetails?.money?.currencyCode,
        };
      }
    }
    let token = paymentRequest?.token;
    let payload = paymentRequest?.payload;
    if (isDccQuotationSelected) payload.dccQuotationId = dccQuotation.dccQuotationId;
    if (showTotalPaymentSummaryField && totalPaymentSummaryValue === "") {
      dispatch(handleTotalPaymentSummaryError("Please enter amount"));
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      updateStroage("session", "remove", "t_id");
      dispatch(initiatePayment({ token, payload }));
      paymentInitiatedEvent(sessionDetails, "Card", latestBrandName, dispatch);
    }
    // if (isIframe && (isFireFox || isIphone)) {
    if (isIframe) {
      setPopupWindowForIframe()
    }
  };
  const handleCardlessEmi = () => {
    const paymentRequest = buildPaymentRequest(
      "emi/cardless",
      updatedShopperDetails,
      sessionDetails
    );
    paymentRequest.payload.instrumentDetails.emi = {
      provider: selectedInstrument?.emiMethod?.cardlessEmiProviderValue,
    };
    if (totalPaymentSummaryValue) {
      paymentRequest.payload.money = {
        amount: totalPaymentSummaryValue,
        currencyCode: sessionDetails?.paymentDetails?.money?.currencyCode,
      };
    }
    if (showTotalPaymentSummaryField && totalPaymentSummaryValue === "") {
      dispatch(handleTotalPaymentSummaryError("Please enter amount"));
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
    } else {
      dispatch(initiatePayment(paymentRequest));
      paymentInitiatedEvent(sessionDetails, "Emi", "CardlessEMI", dispatch);
    }
    if (isIframe) {
      setPopupWindowForIframe()
    }
  };
  const handleCardEmi = () => {
    const [expiry_month, expiry_year] = cardExpiryDate?.split("/");
    const paymentRequest = buildPaymentRequest(
      selectedTabValue,
      updatedShopperDetails,
      sessionDetails,
      emiCode
    );

    paymentRequest.payload.instrumentDetails.card = {
      number: cardNumber,
      expiry: `20${expiry_year}-${expiry_month}`,
      cvc: cardCvc,
      holderName: cardHolderName,
    };
    if (totalPaymentSummaryValue) {
      paymentRequest.payload.money = {
        amount: totalPaymentSummaryValue,
        currencyCode: sessionDetails?.paymentDetails?.money?.currencyCode,
      };
    }
    paymentRequest.payload.instrumentDetails.emi = {
      duration: duration,
    };
    if (showTotalPaymentSummaryField && totalPaymentSummaryValue === "") {
      dispatch(handleTotalPaymentSummaryError("Please enter amount"));
      window.scrollTo({ top: 0, behavior: "smooth" }); // Scroll to the top of the page
    } else {
      dispatch(initiatePayment(paymentRequest));
      paymentInitiatedEvent(sessionDetails, "Emi", "CreditCardEMI", dispatch);
    }
    if (isIframe) {
      setPopupWindowForIframe()
    }
  };
  const handlePay = () => {
    let handlePayment = activePaymentMethod === "Recommended" ? selectedInstrument?.type : activePaymentMethod;
    if (handlePayment === "Emi") {
      if (selectedInstrument && selectedInstrument.brand === "CardlessEMI") {
        handleCardlessEmi();
      } else {
        handleCardEmi();
      }
    } else {
      switch (handlePayment) {
        case "Upi":
        case "UpiSaved":
          handleUpiPay();
          break;
        case "Wallet":
        case "NetBanking":
        case "BuyNowPayLater":
          handleWalletBNPLNetBankingPay();
          break;
        case "Card":
          handleCardPay();
          break;
        default:
          break;
      }
    }
  };
  useEffect(() => {
    if (paymentInitiationLoading && activePaymentMethod !== "UpiQr"
      && (isIframe && (isFireFox || isIphone) && !paymentInitiationData?.paymentMethod?.brand === 'UpiIntent')) {
      setShowPaymentLoading(true);
    } else if (isPaymentRejectedOrFailed) {
      setShowPaymentLoading(false);
    } else if (paymentInitiationData && paymentInitiationData?.actions?.length) {
      const redirectUrl = paymentInitiationData?.actions[0]?.url;
      if (redirectUrl && window.location.href !== redirectUrl && paymentInitiationData.paymentMethod?.type !== "Upi") {
        setShowPaymentLoading(true);
      } else {
        setShowPaymentLoading(false);
      }
    } else {
      setShowPaymentLoading(false);
    }
  }, [paymentInitiationLoading, isPaymentRejectedOrFailed, activePaymentMethod]);
  useEffect(() => {
    if (!paymentInitiationLoading && paymentInitiationData) {
      if (paymentInitiationData?.status?.status === "Approved") {
        if (callerType === "PAYMENT_LINK") {
          window.location.replace(`/finalStatus`);
        }
      }
    }
  }, [paymentInitiationLoading, paymentInitiationData, callerType, dispatch]);
  useEffect(() => {
    if (isPaymentRejectedOrFailed) {
      closeIframePopups();
      dispatch(showFailedModal(true));
    }
  }, [isPaymentRejectedOrFailed, dispatch]);
  const handleExitButton = () => {
    if (isIframe) {
      const message = {
        action: 'paymentCancelled',
      };
      window.parent.postMessage(message, '*');
    } else {
      const redirectUrl = sessionDetails?.paymentDetails?.frontendBackUrl;
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
    }
  };
  const clearPnsFromUrl = () => {
    const url = new URL(window.location.href);
    url.searchParams.delete("pns");
    window.history.pushState({}, "", url.toString());
  };
  const isPaymentButtonDisabled = () => {
    return buttonError ||
      !activePaymentMethod ||
      (activePaymentMethod === "Upi" && !upiVpa) ||
      (activePaymentMethod === "UpiSaved" && !selectedInstrument) ||
      (activePaymentMethod === "Wallet" && !selectedInstrument) ||
      (activePaymentMethod === "BuyNowPayLater" && !selectedInstrument) ||
      (activePaymentMethod === "NetBanking" && !selectedInstrument) ||
      (activePaymentMethod === "Card" && buttonDisableState) ||
      (activePaymentMethod === "Emi" && emiPaymentButton) ||
      (activePaymentMethod === "Recommended" && !selectedInstrument) ||
      (!isEmpty(dccQuotation) && isEmpty(isDccSelected)) ||
      activePaymentMethod === "UpiQr"
  }
  const getAmount = (isDcc) => getNetAmount({ isDcc, activePaymentMethod, selectedInstrument, dccMoney, amount });

  return (
    <div className={isMobileLayout ? "domestic-mbtn-root" : ""}>
      <UpiTimerModal
        upiId={selectedInstrument?.value || upiVpa}
        showTimer={showUpiVpaTimer}
        callback={stopSatusCall}
      />
      {
        isMobileLayout && sessionDetails?.merchantDetails?.convenienceFeeChargedToShopper && (
          <div className="convenience-fee-msz">
            <TranslateText label={"CONVENIENCE_FEE_MSZ"}>
              A convenience fee may be charged while completing your payment
            </TranslateText>
          </div>
        )
      }
      <CustomButton
        text={
          <div className={`dynamic-font-family ${isPaymentButtonDisabled() ? '' : 'dynamic-button-text'}`}
            style={{ color: isPaymentButtonDisabled() ? 'inherit !important' : sessionDetails?.merchantDetails?.checkoutTheme?.buttonTextColor }}
          >
            <TranslateText label={"PAY"}>Pay </TranslateText>
            {
              isDccQuotationSelected ? (
                <>
                  <span className="currency-symbol"> {issuerCurrencyDetails.currencyCode}  </span>
                  {numberToCurrency(getAmount(isDccQuotationSelected), issuerCurrencyDetails.currencyCode)}
                </>
              ) : (
                <>
                  <span className="currency-symbol"> {getSymbolFromCurrency(currencyCode)}  </span>
                  {showTotalPaymentSummaryField ? totalPaymentSummaryValue : numberToCurrency(getAmount(), currencyCode)}
                </>
              )
            }
          </div>
        }
        buttonType={"primary-btn"}
        block={true}
        loading={
          buttonLoading ||
          paymentInitiationLoading ||
          validUpiIdLoading ||
          showPaymentLoading
        }
        disabled={isPaymentButtonDisabled()}
        onClick={handlePay}
      />
      {showPaymentLoading === true && (
        <Loading
          text={t("PLEASE_WAIT_PROCESSING_PAYMENT")}
          subText={t("NOTE_AVOID_BACK_BUTTON")}
          redirectionName={
            activePaymentMethod === "Upi"
              ? paymentInitiationData?.paymentMethod?.subBrand
              : paymentInitiationData?.paymentMethod?.brand
          }
        />
      )}

      {!isEmpty(paymentInitiationData) && <PaymentResponseHandler isIframe={isIframe} popWindowIframeRef={popWindowIframeRef} stopSatusCall={stopSatusCall} />}
      {(sampleToken || isIframePopUpBlocked) ? (
        <ErrorModal
          icon={
            <InfoCircleOutlined
              style={{ color: "#FF4D4F", fontSize: "22px" }}
            />
          }
          title={!isIframePopUpBlocked ? t("PAYMENT_FAILED") : t("POPUP_BLOCKED")}
          desc={!isIframePopUpBlocked ? t("SAMPLE_CHECKOUT_NOTICE") : t('UNBLOCK_POPUP')}
          showModal={failedModal || isIframePopUpBlocked}
          primaryOnClick={() => {
            dispatch(resetPaymentInitiation());
            dispatch(showFailedModal(false));
          }}
          primaryBtnText={!isIframePopUpBlocked ? t("CANCEL") : t("OK")}
        />
      ) : (
        <ErrorModal
          icon={
            <InfoCircleOutlined
              style={{ color: "#FF4D4F", fontSize: "22px" }}
            />
          }
          title={t("PAYMENT_FAILED")}
          desc={(() => {
            const baseMessage = `${t("PAYMENT_RETRY_NOTICE")}.`;
            let errorMessage = "";

            if (
              sessionDetails?.lastTransactionDetails &&
              sessionDetails?.lastTransactionDetails?.status?.reasonCode?.startsWith(
                "UF"
              ) &&
              sessionDetailsStatusReason
            ) {
              errorMessage = sessionDetailsStatusReason;
            } else if (paymentInitiationError) {
              errorMessage = paymentInitiationError;
            } else if (
              paymentSuccessData?.status === "FAILED" &&
              paymentSuccessData?.reasonCode?.startsWith("UF")
            ) {
              errorMessage = activeStatusReason;
            } else if (
              paymentInitiationData?.paymentMethod?.brand === "UpiIntent"
            ) {
              return (
                <div>
                  <TranslateText label={"PAYMENT_FAILED_WITH"}>Payment failed with</TranslateText> {upiIntentName}. <TranslateText label={"RETRY_PAYMENT_WITH"}>Please retry payment with</TranslateText> <TranslateText label={"DIFFERENT_UPI_APP"}>a different UPI app</TranslateText>
                </div>
              );
            }

            return (
              <div>
                {baseMessage}
                {errorMessage && (
                  <>
                    <br />
                    <br />
                    <b><TranslateText label={"ERROR_MSG"}>Error Msg</TranslateText>: </b>
                    {errorMessage}
                  </>
                )}
              </div>
            );
          })()}
          showModal={failedModal}
          primaryOnClick={() => {
            clearPnsFromUrl();
            dispatch(resetTransactionStatus())
            dispatch(resetPaymentInitiation());
            dispatch(showFailedModal(false));
            dispatch(resetFinalStatusResponse());
            dispatch(resetUpiStatus());
            dispatch(resetErrorMsg());
          }}
          secondaryOnClick={() => handleExitButton()}
          primaryBtnText={t("RETRY")}
          secondaryBtnText={t("CANCEL")}
          showSecondaryButton={callerType === "CHECKOUT" && true}
        />
      )}
    </div>
  );
};

export default PyamentButton;
