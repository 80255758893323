import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import CreditCardEmi from "./creditCardEmi";
import DebitCardEmi from "./debitCardEmi";
import OtherEmi from "./otherEmi";
import "./emi.css";
import { useDispatch, useSelector } from "react-redux";
import { removeSelectedIntruments } from "../../../../slices/SavedInstrumentSlice";
import {
  changeBankDropdown,
  changeDebitBankDropdown,
  handleEmiPayButton,
  selectedTab,
} from "../../../../slices/EmiSlice";
import { useTranslation } from "react-i18next";

const Emi = ({ emiMethodInfo }) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);
  const [tabs, setTabs] = useState([
    {
      key: "1",
      id: "CreditCardEMI",
      label: t("CREDIT_CARD_EMI"),
      children: <CreditCardEmi />,
    },
    {
      key: "2",
      id: "DebitCardEMI",
      label: t("DEBIT_CARD_EMI"),
      children: <DebitCardEmi />,
    },
    {
      key: "3",
      id: "CardlessEMI",
      label: t("OTHER_EMI_OPTIONS"),
      children: <OtherEmi emiMethodInfo={emiMethodInfo} />,
    },
  ]);
  const [selectedTabId, setSelectedTabId] = useState("1"); // Initial selected tab ID

  useEffect(() => {
    if (sessionDetails) {
      const updatedTabs = tabs.map((tab) => {
        const paymentMethod = sessionDetails?.configs?.paymentMethods?.find(
          (method) => method?.brand === tab?.id
        );
        const showContent = !!paymentMethod;
        const label = showContent ? paymentMethod?.title : tab.label;

        let obj = { ...tab, showContent, label: t(`${label.toUpperCase().replaceAll(' ', '_')}`) }
        return obj;
      });
      setTabs(updatedTabs);

      const visibleTabs = updatedTabs?.filter((tab) => tab?.showContent);
      if (visibleTabs?.length === 1) {
        setSelectedTabId(visibleTabs[0].key);
      }
    }
  }, [sessionDetails, i18n.language]);

  const visibleTabs = tabs.filter((tab) => tab.showContent);
  const handleTabChange = (key) => {
    setSelectedTabId(key);
    dispatch(removeSelectedIntruments());
  };

  useEffect(() => {
    if (selectedTabId === "1") {
      dispatch(selectedTab("emi/cc"));
      dispatch(changeBankDropdown("All Emi Options"));
      dispatch(handleEmiPayButton(true));
    } else if (selectedTabId === "2") {
      dispatch(selectedTab("emi/dc"));
      dispatch(changeDebitBankDropdown("All Emi Options"));
      dispatch(handleEmiPayButton(true));
    } else {
      dispatch(handleEmiPayButton(true));
    }
  }, [selectedTabId]);

  return (
    <div className="tab-div-styling">
      <Tabs
        defaultActiveKey={selectedTabId}
        items={visibleTabs}
        onChange={handleTabChange}
      />
    </div>
  );
};

export default Emi;
