import { Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./paymentIntrumentSelection.css";
import { CheckCircleFilled } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { generateDynamicStyling, numberToCurrency } from "../../../../Utils/utils";
import {
  selectBaseColor,
  selectCurrencyCode,
} from "../../../../slices/PaymentSessionDetailsSlice";
import {
  selectSelectedIntrument,
  updateSelectedIntruments,
} from "../../../../slices/SavedInstrumentSlice";
import { selectIsMobileLayout } from "../../../../slices/MobileLayoutSlice";
import {
  handleDurationValue,
  handleEMICode,
  handleEmiPayButton,
} from "../../../../slices/EmiSlice";
import EmiPlanDetails from "../emi/emiPlanDetails";
import {
  instrumentProvidedEvent,
  methodSelectedEvent,
} from "../../../../Utils/uiAnalytics";
import getSymbolFromCurrency from "currency-symbol-map";
import { handleTotalPaymentSummaryError } from "../../../../slices/HandleAdditionalComponentsSlice";
import {
  changeUpiVpa,
  handleIdForButton,
  handleUpiError,
  resetUpiFields,
  selectUpiButtonId,
} from "../../../../slices/UpiSlice";
import TranslateText from "../../../resuableComponents/TranslateText";

const PaymentIntrumentSelection = ({
  data,
  searchComponent,
  searchPlaceholder,
}) => {
  const { t } = useTranslation();
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));
  const [selectedIntrumentIdx, setSelectedInstrumentIdx] = useState(data?.length === 1 ? 0 : null);
  const baseColor = useSelector((state) => selectBaseColor(state));
  const currencyCode = useSelector((state) => selectCurrencyCode(state));
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);
  const selectedInstrument = useSelector((state) => selectSelectedIntrument(state));
  const dispatch = useDispatch();
  useEffect(() => {
    if (sessionDetails) {
      generateDynamicStyling(sessionDetails);
    }
  }, [sessionDetails]);

  useEffect(() => {
    if (selectedInstrument) {
      let index = null;
      if (selectedInstrument?.brand === "CardlessEMI") {
        index = data?.findIndex(
          (val) =>
            val?.emiMethod?.cardlessEmiProviderValue ===
            selectedInstrument?.emiMethod?.cardlessEmiProviderValue
        );
      } else if (
        selectedInstrument?.emiMethod?.brand === "CreditCardEMI" ||
        selectedInstrument?.emiMethod?.brand === "DebitCardEMI"
      ) {
        index = data?.findIndex(
          (val) =>
            val?.emiMethod?.duration === selectedInstrument?.emiMethod?.duration
        );

        dispatch(
          handleEMICode(selectedInstrument?.emiMethod?.applicableOffer?.code)
        );
        dispatch(handleDurationValue(selectedInstrument?.emiMethod?.duration));
      } else {
        index = data?.findIndex((val) =>
          selectedInstrument?.value
            ? val?.value === selectedInstrument?.value
            : val?.brand === selectedInstrument?.brand
        );
        if (selectedInstrument?.type === "Upi" && selectedInstrument?.value) {
          dispatch(handleIdForButton("savedVpa"));
          dispatch(changeUpiVpa(selectedInstrument?.value));
          dispatch(handleUpiError(""));
        }
      }
      setSelectedInstrumentIdx(index);
    } else if (data?.length !== 1) {
      dispatch(changeUpiVpa(""));
      dispatch(handleUpiError(""));
      setSelectedInstrumentIdx(null);
      dispatch(resetUpiFields());
    }
  }, [selectedInstrument]);

  useEffect(() => {
    if (data?.length === 1) {
      const singleInstrument = data[0];
      setSelectedInstrumentIdx(singleInstrument);
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (data?.length === 1 && selectedIntrumentIdx) {
      dispatch(updateSelectedIntruments(selectedIntrumentIdx));
    }
  }, [data, selectedIntrumentIdx, dispatch]);

  const getContent = (paymentInfo, idx) => {
    return (
      <div
        style={{
          borderBottom:
            idx !== data?.length - 1 && "1px dashed rgba(0, 0, 0, 0.06)",
        }}
      >
        <div
          className="domestic-pi-item"
          onClick={() => {
            dispatch(updateSelectedIntruments(paymentInfo));
            dispatch(handleTotalPaymentSummaryError(""));
          }}
        >
          <div className="domestic-pi-title-logo">
            {paymentInfo?.brand === "CreditCardEMI" ||
              (paymentInfo?.brand === "DebitCardEMI" ? (
                ""
              ) : (
                <>
                  {paymentInfo?.logoUrl && (
                    <img
                      src={paymentInfo?.logoUrl}
                      style={{ width: "20px", height: "20px" }}
                    />
                  )}
                </>
              ))}

            {paymentInfo?.type === "Emi" &&
              paymentInfo?.brand === "CardlessEMI" ? (
              <Typography className="domestic-pi-header dynamic-font-family">
                {paymentInfo?.emiMethod?.cardlessEmiProviderTitle}
              </Typography>
            ) : paymentInfo?.type === "Emi" &&
              (paymentInfo?.brand === "CreditCardEMI" ||
                paymentInfo?.brand === "DebitCardEMI") ? (
              <div>
                <Typography
                  className="domestic-pi-header dynamic-font-family"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <TranslateText label={"PAY"}>Pay</TranslateText>
                  <span className="planDetailsText">
                    {" "}
                    {getSymbolFromCurrency(currencyCode)}{" "}
                  </span>
                  {numberToCurrency(paymentInfo?.emiMethod?.emiAmount, currencyCode)} {' X '}
                  {paymentInfo?.emiMethod?.duration}  <TranslateText label={"MONTHS"}>months</TranslateText> |{" "}
                  {paymentInfo?.emiMethod?.effectiveInterestRate}%
                </Typography>
                {paymentInfo?.emiMethod?.applicableOffer ? (
                  paymentInfo?.emiMethod?.applicableOffer?.discount?.type ===
                    "LowCostEmi" ? (
                    <div className="no-cost-emi">
                      <Typography className="no-cost-text">
                        <TranslateText label={"LOW_COST_EMI_AVAILABLE"}>Low-Cost EMI available</TranslateText>
                      </Typography>
                    </div>
                  ) : paymentInfo?.emiMethod?.applicableOffer?.discount
                    ?.type === "NoCostEmi" ? (
                    <div className="no-cost-emi">
                      <Typography className="no-cost-text">
                        <TranslateText label={"NO_COST_EMI_AVAILABLE"}>No-Cost EMI available</TranslateText>
                      </Typography>
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
              </div>
            ) : (
              <Typography className="domestic-pi-header dynamic-font-family">
                {paymentInfo?.title || paymentInfo?.displayValue}
              </Typography>
            )}
          </div>
          <CheckCircleFilled
            style={{
              color: selectedIntrumentIdx === idx ? baseColor : "#ECECED",
              fontSize: "24px",
            }}
          />
        </div>
        {selectedInstrument &&
          selectedInstrument?.type === "Emi" &&
          selectedInstrument?.brand !== "CardlessEMI" &&
          selectedInstrument?.emiMethod?.duration ===
          paymentInfo?.emiMethod?.duration && (
            <EmiPlanDetails emiMethodInfo={selectedInstrument} paymentInfo={paymentInfo} />
          )}
      </div>
    );
  };

  const options = [];
  const topBanks = [
    "HDFC Bank",
    "ICICI Bank",
    "State Bank of India",
    "Axis Bank",
  ];

  data?.map((val, idx) => {
    options.push({
      label: val?.title || val?.displayValue,
      value: val?.title,
    });
  });

  useEffect(() => {
    if (
      selectedInstrument !== null &&
      selectedInstrument?.brand === "CardlessEMI"
    ) {
      dispatch(handleEmiPayButton(false));
    }
  }, [selectedInstrument]);

  useEffect(() => {
    if (selectedInstrument) {
      const paymentType = selectedInstrument?.type;
      const paymentSubType = selectedInstrument?.brand;
      methodSelectedEvent(
        sessionDetails,
        paymentType,
        paymentSubType,
        dispatch
      );
      instrumentProvidedEvent(
        sessionDetails,
        paymentType,
        paymentSubType,
        dispatch
      );
    }
  }, [selectedInstrument]);
  return (
    <div className="domestic-pi-selection-root">
      {data?.map((paymentInfo, idx) => {
        return (
          <>
            {paymentInfo?.type === "NetBanking"
              ? topBanks?.find((title) => title === paymentInfo?.title) &&
              getContent(paymentInfo, idx)
              : getContent(paymentInfo, idx)}
          </>
        );
      })}
      {!isMobileLayout &&
        data?.length > 0 &&
        data[0]?.type === "NetBanking" && (
          <div style={{ marginTop: "20px" }}>
            <Typography
              className="regular14Heading dynamic-font-family"
              style={{ color: "var(--Neutral-Grey-1000)" }}
            >
              <TranslateText label={"ALL_BANKS"}>All Banks</TranslateText>
            </Typography>
          </div>
        )}

      {searchComponent && (
        <div style={{ marginTop: "12px" }}>
          <Select
            showSearch
            style={{ width: "100%" }}
            placeholder={searchPlaceholder}
            options={options}
            onChange={(value) => {
              let method = data?.find(
                (paymentInfo) => paymentInfo?.title === value
              );
              dispatch(updateSelectedIntruments(method));
            }}
          />
        </div>
      )}
    </div>
  );
};

export default PaymentIntrumentSelection;
