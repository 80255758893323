const workercode = () => {
  let timer = 0;
  /* eslint-disable-next-line no-restricted-globals */
  self.onmessage = function ({ data }) {    
    if (data.action === "start" || !data.action) {
      const startTimeInSeconds = new Date();
      timer = setInterval(() => {
        const currentTimeInSeconds = new Date();
        const remainingTime =
          data?.duration -
          Math.floor((currentTimeInSeconds - startTimeInSeconds) / 1000);
        if (remainingTime <= 0) {
          clearInterval(timer);
          /* eslint-disable-next-line no-restricted-globals */
          self.postMessage("done");
        } else {
          /* eslint-disable-next-line no-restricted-globals */
          self.postMessage(remainingTime);
        }
      }, 1000);
    } else if (data.action === "stop") {
      if (timer) {
        clearInterval(timer); // Stop the current timer
      }
      /* eslint-disable-next-line no-restricted-globals */
      self.postMessage("stopped");
    }
  };
};

let code = workercode.toString();
code = code.substring(code.indexOf("{") + 1, code.lastIndexOf("}"));

const blob = new Blob([code], { type: "application/javascript" });
const timer_worker_script = URL.createObjectURL(blob);

export default timer_worker_script;
