/* eslint-disable jsx-a11y/alt-text */
import Typography from "antd/es/typography/Typography";
import React, { useEffect, useRef, useState } from "react";
import CustomButton from "../../../resuableComponents/CustomButton";
import { useDispatch, useSelector } from "react-redux";
import { selectDarkColor } from "../../../../slices/PaymentSessionDetailsSlice";
import { buildPaymentRequest } from "../../../../Utils/paymentUtils";
import {
  initiatePayment,
  resetPaymentInitiation,
} from "../../../../slices/PaymentInitiationSlice";
import timer_worker_script from "../../../../web-workers/timerWorker";
import { formatOtpTime, isEmpty } from "../../../../Utils/utils";
import {
  selectActivePaymentMethod,
  changeActivePaymentMethod,
} from "../../../../slices/PaymentButtonSlice";
import {
  instrumentProvidedEvent,
  methodSelectedEvent,
  paymentInitiatedEvent,
} from "../../../../Utils/uiAnalytics";
import { handleTotalPaymentSummaryError } from "../../../../slices/HandleAdditionalComponentsSlice";
import TranslateText from "../../../resuableComponents/TranslateText";
import { useTranslation } from "react-i18next";
import PyamentButton from "../../paymentButton/PaymentButton";
import ErrorModal from "../../../../ant-design/components/modals/errorModal/ErrorModal";
import { InfoCircleOutlined } from "@ant-design/icons";

const UpiQr = ({ upiData }) => {
  const { t } = useTranslation()
  const darkcolor = useSelector((state) => selectDarkColor(state));
  const updatedShopperDetails = useSelector((state) => state.paymentSessionDetails.updatedShopperDetails);
  const paymentInitiationData = useSelector((state) => {
    let data = state.paymentInitiation.paymentInitiationData;
    return data;
  });
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);
  const defaultDuration = 300;
  const expiryDuration =
    paymentInitiationData?.actions?.length > 0
      ? paymentInitiationData?.actions[0]?.expirySec
      : null;
  const duration = expiryDuration || defaultDuration;
  const [shouldPayBtnBeVisible, setShouldPayBtnBeVisible] = useState(false);
  const [countdown, setCountdown] = useState(duration);
  const dispatch = useDispatch();
  const [qrActive, setQrActive] = useState(0);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const activePaymentMethod = useSelector((state) => selectActivePaymentMethod(state));
  const showTotalPaymentSummaryField = useSelector((state) => state.additionalComponents.showTotalPaymentSummaryField);
  const timerRef = useRef(null);
  const paymentInitiationLoading = useSelector((state) => state.paymentInitiation.paymentInitiationLoading);
  const totalPaymentSummaryValue = useSelector((state) => state.additionalComponents.totalPaymentSummaryValue);
  const paymentMethodDictionary = useSelector((state) => state?.paymentSessionDetails?.paymentMethodDictionary);

  useEffect(() => {
    if (paymentMethodDictionary) {
      setShouldPayBtnBeVisible(!paymentMethodDictionary['Upi']?.some(i => i.brand === 'UpiCollect'));
    }
  }, [paymentMethodDictionary])

  useEffect(() => {
    if (activePaymentMethod !== "UpiQr") {
      if (timerRef.current) {
        timerRef.current.onmessage = ({ data }) => {
          clearInterval(timerRef.current);
        };
        setCountdown(defaultDuration);
        setQrActive(0);
      }
    }
  }, [activePaymentMethod]);

  // Stop the timer
  const stopTimer = () => {
    timerRef.current.postMessage({ action: "stop" });
  };
  useEffect(() => {
    if (paymentInitiationData != null && paymentInitiationData?.paymentMethod?.brand === "UpiQr") {
      setQrActive(1);
      const expiryDuration = paymentInitiationData?.actions?.length > 0 ? paymentInitiationData?.actions[0]?.expirySec : defaultDuration;
      setCountdown(expiryDuration);
      timerRef.current = new Worker(timer_worker_script);
      timerRef.current.postMessage({
        action: "start",
        duration: duration,
      })
      timerRef.current.onmessage = ({ data }) => {
        if (data === "done") {
          setCountdown(expiryDuration);
          setQrActive(2);
          stopTimer();
          clearInterval(timerRef.current);
          dispatch(resetPaymentInitiation());
        } else {
          setCountdown(data);
        }
      };
      if (paymentInitiationData?.actions?.length === 0) {
        return setShowErrorModal(true);
      }
    } else {
      setQrActive(0);
    }
  }, [paymentInitiationData]);

  const upiApps = [
    {
      id: "tez",
      name: "Google Pay",
      logo: "/assets/intent/google-pay.svg",
    },
    {
      id: "PhonePe",
      name: "PhonePe",
      logo: "/assets/intent/phonepe.svg",
    },

    {
      id: "paytmmp",
      name: "Paytm",
      logo: "/assets/intent/paytm.svg",
    },
    {
      id: "upi",
      name: "upi",
      logo: "/assets/intent/upi.svg",
    },
  ];

  const handleQrPay = () => {
    let paymentRequest = buildPaymentRequest(
      upiData?.instrumentTypeValue,
      updatedShopperDetails,
      sessionDetails
    );
    if (totalPaymentSummaryValue) {
      paymentRequest.payload.money = {
        amount: totalPaymentSummaryValue,
        currencyCode: sessionDetails?.paymentDetails?.money?.currencyCode,
      };
    }
    let token = paymentRequest?.token;
    let payload = paymentRequest?.payload;
    methodSelectedEvent(sessionDetails, "Upi", "UpiQr", dispatch);
    instrumentProvidedEvent(sessionDetails, "Upi", "UpiQr", dispatch);
    if (showTotalPaymentSummaryField && totalPaymentSummaryValue === "") {
      dispatch(handleTotalPaymentSummaryError("Please enter amount"));
      window.scrollTo({ top: 0, behavior: "smooth" });
    } else {
      dispatch(initiatePayment({ token, payload }));
      paymentInitiatedEvent(sessionDetails, "Upi", "UpiQr", dispatch);
    }
  };

  const handlePrimaryClick = () => {
    setShowErrorModal(false)
    stopTimer();
    setQrActive(1);
    dispatch(resetPaymentInitiation());
  }

  const style = {
    background: `linear-gradient(to right, ${darkcolor} 4px, transparent 4px) 0 0 , linear-gradient(to right, ${darkcolor} 4px, transparent 4px) 0 100% , linear-gradient(to left, ${darkcolor} 4px, transparent 4px) 100% 0,linear-gradient(to left, ${darkcolor} 4px, transparent 4px) 100% 100%,linear-gradient(to bottom, ${darkcolor} 4px, transparent 4px) 0 0,linear-gradient(to bottom, ${darkcolor} 4px, transparent 4px) 100% 0,linear-gradient(to top, ${darkcolor} 4px, transparent 4px) 0 100%,linear-gradient(to top, ${darkcolor} 4px, transparent 4px) 100% 100%`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "20px 20px",
  };

  return (
    <div className="upi-qr-root">
      <div className="qr-code-btn">
        <div className="qr" style={style}>
          <img
            src={
              paymentInitiationData &&
                paymentInitiationData?.actions?.length > 0
                ? `data:image/gif;base64,${paymentInitiationData?.actions[0]?.content}`
                : "/assets/intent/qr.png"
            }
            style={{
              filter:
                qrActive === 0 || qrActive === 2 || !qrActive
                  ? "blur(3px)"
                  : "",
              width: "150px",
              height: "150px",
            }}
          />
        </div>
        <div
          className="generate-qr"
          style={{
            display: qrActive === 1 ? "none" : "",
          }}
        >
          <CustomButton
            text={qrActive === 0 ? t("VIEW") : t("REFRESH")}
            containerType={"default"}
            block={true}
            loading={paymentInitiationLoading}
            padding={"4px 12px"}
            onClick={() => {
              handleQrPay();
              dispatch(changeActivePaymentMethod("UpiQr"));
            }}
          />
        </div>
      </div>
      <div className="qr-desc">
        <Typography
          className="regular12Heading dynamic-font-family"
          style={{ color: "var(-Neutral-Grey-1100)" }}
        >
          <TranslateText label={"SCAN_QR_USING_UPI_APP"}>Scan the QR using any UPI app on your phone</TranslateText>

        </Typography>
        <div className="upi-app-img-section">
          {upiApps.map((app) => {
            return <img key={app?.id} src={app?.logo} width={15} height={13} />;
          })}
        </div>
        {paymentInitiationData != null && (
          <div>
            <Typography
              className="regular12Heading dynamic-font-family"
              style={{ color: "var(-Neutral-Grey-1100)" }}
            >
              <TranslateText label={"QR_CODE_VALID_FOR"}>QR Code is valid for</TranslateText>
            </Typography>{" "}
            <Typography className="regular12Heading dynamic-font-family">
              <span
                className="semiBold12Heading dynamic-font-family"
                style={{ color: "var(--Red-800)" }}
              >
                {formatOtpTime(countdown)}{" "}
              </span>
              <TranslateText label={"MINUTES"}>minutes</TranslateText>
            </Typography>
          </div>
        )}
      </div>
      {
        shouldPayBtnBeVisible && (
          <div className="upiQrPayButton">
            <PyamentButton />
          </div>
        )
      }

      {
        showErrorModal && (
          <ErrorModal
            icon={
              <InfoCircleOutlined style={{ color: "#FF4D4F", fontSize: "22px" }} />
            }
            title={`${t("UNABLE_TO_LOAD_QR")}`}
            desc={t("UNABLE_TO_LOAD_QR_DESC")}
            showModal={showErrorModal}
            primaryOnClick={() => { handlePrimaryClick() }}
            primaryBtnText={`${t("Ok")}`}
            showSecondaryButton={false}
          />
        )
      }
    </div>
  );
};

export default UpiQr;
