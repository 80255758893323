/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Form, Input, Tooltip, Typography } from "antd";
import { useTranslation } from "react-i18next";
import TranslateText from "../../../resuableComponents/TranslateText";
import {
  formatExpiry,
  isValidCardNumber,
  isValidExpiryDate,
  formatCardNumber,
  isEmpty,
  debounce
} from "../../../../Utils/utils";
import { useDispatch, useSelector } from "react-redux";
import { getCardBinData, resetCardBinData } from "../../../../slices/BinSlice";
import { CreditCardFilled } from "@ant-design/icons";
import { getIcon } from "./getCardLogos";
import { selectIsMobileLayout } from "../../../../slices/MobileLayoutSlice";
import PyamentButton from "../../paymentButton/PaymentButton";
import {
  changeCardNumber,
  changeCardHolderName,
  changeExpiryDate,
  changeCardCvc,
  changeBrandName,
  cardPayButtonState,
  getDccQuotations,
  resetDccQuotation,
} from "../../../../slices/CardSlice";
import { selectSelectedIntrument } from "../../../../slices/SavedInstrumentSlice";
import { creditBankSelected, debitBankSelected, handleEmiPayButton } from "../../../../slices/EmiSlice";
import { instrumentProvidedEvent } from "../../../../Utils/uiAnalytics";
import { selectActivePaymentMethod, selectPaymentMethodInfo } from "../../../../slices/PaymentButtonSlice";
import { buildDccRequest, buildPaymentRequest } from "../../../../Utils/paymentUtils";
import DccQuotation from "../../dccQuotation/DccQuotation";
const { Text } = Typography;
const CARD_CHECK_LENGTH = 9;
const CARD_MAX_LENGTH = 16;


export default function CardPaymentForm(props) {
  const { t, i18n } = useTranslation();
  const { paymentType, cardType, selectedEmiBankTitle } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const sessionDetails = useSelector((state) => state.paymentSessionDetails.sessionDetails);
  const isMobileLayout = useSelector((state) => selectIsMobileLayout(state));
  const cardBinData = useSelector((state) => state.binDetails.cardBinData);
  const [cardNumber, setCardNumber] = useState("");
  const [nameOnCard, setNameOnCard] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvcOnCard, setCvcOnCard] = useState("");
  const [brandName, setBrandName] = useState("");
  const [methodEnabled, setMethodEnabled] = useState("");
  const [cardNumberError, setCardNumberError] = useState("");
  const [expiryDateError, setExpiryDateError] = useState("");
  const [nameError, setNameError] = useState("");
  const [cvcError, setCvcError] = useState("");
  const activePaymentMethod = useSelector((state) => selectActivePaymentMethod(state));
  const emiCreditBankSelected = useSelector((state) => creditBankSelected(state));
  const emiDebitBankSelected = useSelector((state) => debitBankSelected(state));
  const selectedInstrument = useSelector((state) => selectSelectedIntrument(state));
  const updatedShopperDetails = useSelector((state) => state.paymentSessionDetails.updatedShopperDetails);
  const dccQuotation = useSelector(state => state.cardDetails.dccQuotation);
  const DCC_CARD_MAX_LENGTH = useSelector(state => state.cardDetails.DCC_CARD_MAX_LENGTH);
  const token = sessionDetails?.token;
  const icons = getIcon(brandName);
  const isPasted = useRef(false);

  const getCVVlength = () => {
    return brandName === "AmericanExpress" ? 4 : 3
  }
  useEffect(() => {
    if (!isEmpty(sessionDetails?.paymentDetails?.shopper)) {
      const { firstName, lastName } = sessionDetails?.paymentDetails?.shopper;
      const fullName = `${firstName || ''} ${lastName || ''}`
      setNameOnCard(fullName);
    }
  }, [sessionDetails]);
  const handleCardBinDataCall = useCallback(({ token, inputData: value }) => {
    dispatch(getCardBinData({ token, inputData: value }));
  }, []);
  const debouncedCardBinData = useCallback(debounce(handleCardBinDataCall, 500), []);
  const handleCardNumber = (e) => {
    e.target.value = e.target.value.replace(/\D/g, "");
    e.target.value = e.target.value.slice(0, CARD_MAX_LENGTH);
    const { value } = e.target;

    /* adds space every 4 digits 22223333 -> 2222 3333 */
    const formattedValue = formatCardNumber(value, brandName);
    setCardNumber(formattedValue);
    dispatch(changeCardNumber(value));

    if (value.length === CARD_MAX_LENGTH) {
      debouncedCardBinData({ token, inputData: value })
      if (!isValidCardNumber(value) || isEmpty(value)) {
        setCardNumberError(isEmpty(value) ? t("REQUIRED") : t("INVALID_CARD_NUMBER"));
      } else {
        setCardNumberError(''); dispatch(resetCardBinData());
      }
    }

    /*** when card length greater than CARD_CHECK_LENGTH then check card scheme and also if the payment is allowed for this card. ***/
    if ((value.length >= CARD_CHECK_LENGTH && isEmpty(cardBinData)) || (isPasted.current && value.length >= CARD_CHECK_LENGTH)) {
      debouncedCardBinData({ token, inputData: value })
    } else if (value.length < CARD_CHECK_LENGTH) {
      dispatch(resetCardBinData());
    }

    /* set form data */
    form.setFieldsValue({ cardNumber: formattedValue });
    if (formattedValue?.length < CARD_CHECK_LENGTH) setBrandName("");
    instrumentProvidedEvent(sessionDetails, paymentType, brandName, dispatch);
  };
  useEffect(() => {
    if (isEmpty(cardNumber)) dispatch(resetCardBinData());
  }, [cardNumber])

  // DCC code starts
  useEffect(() => {
    if (isEmpty(dccQuotation)) return;
    // if Issuer and merchant both have same currency then hide dcc auotation
    if (dccQuotation?.baseMoney?.currencyCode === dccQuotation?.dccQuotationDetails?.dccMoney?.currencyCode) {
      dispatch(resetDccQuotation());
    }
  }, [dccQuotation])
  const handleDccQuotationCall = useCallback(({ token, req }) => {
    dispatch(getDccQuotations({ token, req }));
  }, []);
  const debouncedDccQuotation = useCallback(debounce(handleDccQuotationCall, 500), []);
  useEffect(() => {
    const sanitizedCard = cardNumber.replaceAll(" ", "");
    if (sanitizedCard.length >= DCC_CARD_MAX_LENGTH && !isEmpty(cardBinData)) {
      let { brand } = cardBinData.paymentMethod;
      let instrument = { brand, accountNumber: sanitizedCard }
      let req = buildDccRequest({ instrument, updatedShopperDetails, sessionDetails });
      debouncedDccQuotation({ token, req })
    } else {
      dispatch(resetDccQuotation());
    }
  }, [cardNumber, cardBinData])
  //DCC code ends

  useEffect(() => {
    /* handle EMI errors when issuer mismatched or not found */
    const { issuerName, issuerTitle, paymentMethod: { classification } = {} } = cardBinData || {};
    let selectedCardValue = cardType?.toLowerCase() === 'credit' ? emiCreditBankSelected : emiDebitBankSelected;
    if (activePaymentMethod?.toLowerCase() === 'emi' && !isEmpty(cardBinData) && !isEmpty(selectedCardValue)) {
      if (isEmpty(issuerName) || isEmpty(classification)) {
        setCardNumberError(t("NO_EMI_FOUND_FOR_CARD"));
      } else if (issuerTitle && issuerName?.toLowerCase() !== selectedCardValue.toLowerCase()) {
        let msz = `${t("CARD_IS")} ${issuerTitle} ${classification} ${t("CARD")}. ${t("ENTER_CARD_NUMBER")} ${selectedEmiBankTitle} ${cardType}  ${t("CARD")}`
        setCardNumberError(msz);
      } else if (issuerTitle && issuerName?.toLowerCase() === selectedCardValue.toLowerCase()) {
        setCardNumberError('');
      }
    } else {
      // setCardNumberError('');
    }
    /* handle EMI errors when issuer mismatched or not found */
  }, [cardBinData, emiCreditBankSelected, selectedEmiBankTitle, emiDebitBankSelected])

  useEffect(() => {
    const sanitizedCard = cardNumber.replaceAll(" ", "");
    /* sets card scheme icon if the card is supported */
    if (!isEmpty(cardBinData)) {
      const brands = cardBinData?.paymentMethod?.brand;
      const methodEnabled = cardBinData?.methodEnabled;

      setMethodEnabled(methodEnabled);
      setBrandName(brands);
    } else if (sanitizedCard?.length < CARD_CHECK_LENGTH || sanitizedCard?.length === 1) {
      setBrandName("");
      form.setFieldsValue({ cvcOnCard: "" });
      setCvcOnCard("");
    }
  }, [cardBinData]);
  const handleNameOnCard = (e) => {
    const name = e.target.value;
    if (name) setNameError("");
    setNameOnCard(name);
    dispatch(changeCardHolderName(name));
    instrumentProvidedEvent(
      sessionDetails,
      paymentType,
      brandName,
      dispatch
    );
  };
  const handleExpiryDate = (e) => {
    const { value } = e.target;
    if (value.length === 5) {
      if ((!isValidExpiryDate(value) || isEmpty(value))) setExpiryDateError(isEmpty(value) ? t("REQUIRED") : t("INVALID_EXPIRY"));
      else setExpiryDateError('')
    }
    const formattedValue = formatExpiry(value);
    setExpiryDate(formattedValue);
    dispatch(changeExpiryDate(formattedValue));
    form.setFieldsValue({ expiryDate: formattedValue });
    instrumentProvidedEvent(
      sessionDetails,
      paymentType,
      brandName,
      dispatch
    );
  };
  const handleCvcCard = (e) => {
    let inputValue = e.target.value.replace(/\D/g, "");
    setCvcOnCard(inputValue);
    dispatch(changeCardCvc(inputValue));
    form.setFieldsValue({ cvcOnCard: inputValue });
    instrumentProvidedEvent(
      sessionDetails,
      paymentType,
      brandName,
      dispatch
    );
  };
  const getPayButtonState = () => {
    if (isValidCardNumber(cardNumber.replaceAll(" ", ""))
      && isValidExpiryDate(expiryDate)
      && !isEmpty(nameOnCard)
      && getCVVlength() === cvcOnCard.length) {
      dispatch(cardPayButtonState(false));
    } else {
      dispatch(cardPayButtonState(true));
    }
  }
  useEffect(() => {
    if (brandName && methodEnabled === false) {
      setCardNumberError(t("CARD_NOT_SUPPORTED"));
      dispatch(cardPayButtonState(true));
    } else {
      getPayButtonState();
    }
  }, [
    cardNumber,
    nameOnCard,
    expiryDate,
    cvcOnCard,
    cardNumberError,
    expiryDateError,
    cvcError,
    brandName,
    methodEnabled,
  ]);
  useEffect(() => {
    if (brandName) {
      dispatch(changeBrandName(brandName));
    }
  }, [brandName]);
  useEffect(() => {
    if (paymentType === "Emi") {
      if (selectedInstrument?.brand !== "CardlessEmi") {
        if (
          cardNumber &&
          nameOnCard &&
          expiryDate &&
          cvcOnCard &&
          selectedInstrument
        ) {
          dispatch(handleEmiPayButton(false));
        }
        if (
          cardNumber?.length === 0 ||
          nameOnCard?.length === 0 ||
          expiryDate?.length === 0 ||
          cvcOnCard?.length === 0 ||
          cardNumberError?.length ||
          expiryDateError?.length ||
          cvcError?.length ||
          nameOnCard?.length === 0
        ) {
          dispatch(handleEmiPayButton(true));
        }

        if (brandName && methodEnabled === false) {
          dispatch(handleEmiPayButton(true));
        }
      }
    }
  }, [
    paymentType,
    selectedInstrument,
    cardNumber,
    nameOnCard,
    expiryDate,
    cvcOnCard,
    cardNumberError,
    expiryDateError,
    cvcError,
    brandName,
    methodEnabled,
  ]);
  useEffect(() => {
    return () => {
      dispatch(resetCardBinData());
    }
  }, []);
  useEffect(() => {
    const handleLanguageChange = () => {
      const fieldsWithErrors = ['cardNumber', 'nameOnCard', 'expiryDate', 'cvcOnCard']
        .filter((field) =>
          form.getFieldError(field).length > 0
        );

      if (fieldsWithErrors.length > 0) {
        form.validateFields(fieldsWithErrors);
      }
    };

    // Listen for language changes
    i18n.on('languageChanged', handleLanguageChange);
    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n, form]);

  return (
    <>
      <Form
        requiredMark={"optional"}
        layout="vertical"
        name="addresss"
        form={form}
        autoComplete="off"
      >
        <div className="domestic-pi-selection-root cardNumberField">
          <Form.Item
            label={
              <Text className="regular14Heading deliveryTitle dynamic-font-family textWrapSingleLine"
                title={t("CARD_NUMBER")}>
                <TranslateText label={"CARD_NUMBER"}>Card Number</TranslateText>*
              </Text>
            }
            name="cardNumber"
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                message: t("REQUIRED"),
              },
              {
                validator: (_, value) => {
                  if (!isValidCardNumber(value) || isEmpty(value)) return setCardNumberError(isEmpty(value) ? t("REQUIRED") : t("INVALID_CARD_NUMBER")), Promise.reject();
                  else if (isEmpty(cardNumberError)) {
                    return setCardNumberError(""), Promise.resolve();
                  }
                },
              },
            ]}
            validateStatus={cardNumberError ? "error" : ""}
            help={cardNumberError}
          >
            <>
              <Input
                autoComplete="new-password"
                placeholder={`${t("ENTER_CARD_NUMBER_HERE")}`}
                value={cardNumber}
                id="cardNumberInput"
                onFocus={() => { return activePaymentMethod?.toLowerCase() !== 'emi' && setCardNumberError("") }}
                onChange={handleCardNumber}
                onPaste={() => isPasted.current = true}
                inputMode="numeric"
                pattern="[0-9]*"
                addonAfter={
                  icons && icons?.length > 0 ? (
                    icons[0]
                  ) : (
                    <CreditCardFilled className="defaultCardColor" />
                  )
                }
              />
            </>
          </Form.Item>
          <Form.Item
            label={
              <Text className="regular14Heading deliveryTitle textWrapSingleLine dynamic-font-family"
                title={t("NAME_ON_CARD")}>
                <TranslateText label={"NAME_ON_CARD"}>Name on Card</TranslateText>*
              </Text>
            }
            name="nameOnCard"
            validateTrigger="onBlur"
            rules={[
              {
                required: true,
                message: t("REQUIRED"),
              },
              {
                validator: (_, value) => {
                  if (!value) return setNameError(t("REQUIRED")), Promise.reject();
                  else return setNameError(""), Promise.resolve();
                },
              },
            ]}
            validateStatus={nameError ? "error" : ""}
            help={nameError}
          >
            <>
              <Input
                placeholder={`${t("ENTER_YOUR_NAME")}`}
                value={nameOnCard}
                onFocus={() => setNameError("")}
                onChange={handleNameOnCard}
              />
            </>
          </Form.Item>
          <div className="cvvDiv">
            <Form.Item
              label={
                <Text className="regular14Heading deliveryTitle textWrapSingleLine dynamic-font-family"
                  title={t("EXPIRY_DATE")}>
                  <TranslateText label={"EXPIRY_DATE"}>Expiry Date</TranslateText>*
                </Text>
              }
              name="expiryDate"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: t("REQUIRED"),
                },
                {
                  validator: (_, value) => {
                    if (!isValidExpiryDate(value) || isEmpty(value)) return setExpiryDateError(isEmpty(value) ? t("REQUIRED") : t("INVALID_EXPIRY")), Promise.reject(t("REQUIRED"));
                    else return setExpiryDateError(""), Promise.resolve();
                  },
                },
              ]}
              validateStatus={expiryDateError ? "error" : ""}
              help={expiryDateError}
            >
              <>
                <Input
                  placeholder={`${t("MONTH_YEAR")}`}
                  value={expiryDate}
                  onChange={handleExpiryDate}
                  onFocus={() => setExpiryDateError("")}
                  inputMode="numeric"
                  pattern="[0-9]*"
                />
              </>
            </Form.Item>
            <Form.Item
              label={
                <>
                  <Text className="regular14Heading deliveryTitle textWrapSingleLine dynamic-font-family"
                    title={t("CARD_CVV")}>
                    <TranslateText label={"CARD_CVV"}>Card CVV</TranslateText>*
                  </Text>
                  <Tooltip
                    placement="right"
                    title={t("CARD_CVV_DESCRIPTION_INFO")}
                  >
                    <img
                      src="/assets/address/help-circle.svg"
                      alt="help-circle"
                      style={{ marginLeft: "4px" }}
                    />
                  </Tooltip>
                </>
              }
              name="cvcOnCard"
              validateTrigger="onBlur"
              rules={[
                {
                  required: true,
                  message: t("REQUIRED"),
                },
                {
                  validator: (_, value) => {
                    let cvcLength = getCVVlength();
                    // let cvcLength = brandName === "AmericanExpress" ? 4 : 3;
                    if (value.length !== cvcLength || isEmpty(value))
                      return setCvcError(isEmpty(value) ? t("REQUIRED") : t("INVALID_CVV")), Promise.reject(t("REQUIRED"));

                  },
                },
              ]}
              validateStatus={cvcError ? "error" : ""}
              help={cvcError}
            >
              <>
                <Input
                  type="password"
                  placeholder={t("ENTER_CARD_CVV")}
                  value={cvcOnCard}
                  onChange={handleCvcCard}
                  maxLength={getCVVlength()}
                  onFocus={() => setCvcError("")}
                  inputMode="numeric"
                  pattern="[0-9]*"
                />
              </>
            </Form.Item>
          </div>
          {
            !isEmpty(dccQuotation) && (<DccQuotation />)
          }
          <div
            className="buttonDiv"
            style={{ display: isMobileLayout ? "none" : "" }}
          >
            <PyamentButton />
          </div>
        </div>
      </Form>
    </>
  );
}
