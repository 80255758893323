import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

const baseUrl = process.env.REACT_APP_BASE_URL;

export const getAllSavedAddress = createAsyncThunk(
  "savedAddressDetails/getAllSavedAddress",
  async ({ shopperRef, sessionToken, checkoutToken }) => {
    const response = await axios.get(
      `${baseUrl}/checkout/sessions/${checkoutToken}/shoppers/${shopperRef}/addresses`,
      {
        headers: {
          Authorization: `Session ${sessionToken}`,
        },
      }
    );
    return response?.data;
  }
);

export const addSavedAddress = createAsyncThunk(
  "savedAddressDetails/addSavedAddress",
  async (data, { rejectWithValue }) => {
    const { shopperRef, payload, sessionToken, checkoutToken } = data;
    try {
      const response = await axios.post(
        `${baseUrl}/checkout/sessions/${checkoutToken}/shoppers/${shopperRef}/addresses`,
        payload,
        {
          headers: {
            Authorization: `Session ${sessionToken}`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      if (error?.response) {
        return rejectWithValue(error?.response?.data);
      } else {
        return rejectWithValue(error?.message);
      }
    }
  }
);
export const updateSavedAddress = createAsyncThunk(
  "savedAddressDetails/updateSavedAddress",
  async (data) => {
    const { shopperRef, payload, sessionToken, labelType, labelName, checkoutToken } = data;
    let url = `${baseUrl}/checkout/sessions/${checkoutToken}/shoppers/${shopperRef}/addresses/${labelType}`;
    if (labelName) {
      url += `-${labelName}`;
    }
    try {
      const response = await axios.put(url, payload, {
        headers: {
          Authorization: `Session ${sessionToken}`,
        },
      });
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deleteSavedAddress = createAsyncThunk(
  "savedAddressDetails/deleteSavedAddress",
  async (data) => {
    const { shopperRef, sessionToken, addressRef, checkoutToken } = data;
    try {
      const response = await axios.delete(
        `${baseUrl}/checkout/sessions/${checkoutToken}/shoppers/${shopperRef}/addresses/${addressRef}`,
        {
          headers: {
            Authorization: `Session ${sessionToken}`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      throw error;
    }
  }
);
const initialState = {
  allSavedAddresses: [],
  allSavedAddressesLoading: false,
  allSavedAddressesErrorMessage: "",

  savedAddress: [],
  savedAddressesLoading: false,
  savedAddressesErrorMessage: "",
  isConflictedEntity: false,
  conflictedLabelName: "",

  updatedSavedAddressData: [],
  updatedSavedAddressesLoading: false,
  updatedSavedAddressesErrorMessage: "",

  deleteSavedAddressData: [],
  deleteSavedAddressesLoading: false,
  deleteSavedAddressesErrorMessage: "",
  showBackButton: false,

  addressButtonLoading: false,
};

export const savedAddressDetailsSlice = createSlice({
  name: "savedAddressDetails",
  initialState,
  reducers: {
    showGoBackButton(state, action) {
      state.showBackButton = action.payload;
    },
    removeAllSavedAddress(state, action) {
      state.allSavedAddresses = [];
    },
    handleAddressConflictModal(state, action) {
      state.isConflictedEntity = action.payload;
      state.conflictedLabelName = "";
    },
  },
  extraReducers: {
    [getAllSavedAddress.pending]: (state) => {
      state.allSavedAddressesLoading = true;
    },
    [getAllSavedAddress.fulfilled]: (state, action) => {
      state.allSavedAddressesLoading = false;
      state.allSavedAddresses = action.payload;
    },
    [getAllSavedAddress.rejected]: (state, action) => {
      state.allSavedAddressesLoading = false;
      state.allSavedAddresses = [];
      state.allSavedAddressesErrorMessage = action.error.payload;
    },
    [addSavedAddress.pending]: (state) => {
      state.savedAddressesLoading = true;
      state.addressButtonLoading = true;
      state.isConflictedEntity = false;
    },
    [addSavedAddress.fulfilled]: (state, action) => {
      state.savedAddressesLoading = false;
      state.addressButtonLoading = false;
      state.savedAddress = action.payload;
      state.isConflictedEntity = false;
      state.conflictedLabelName = "";
    },
    [addSavedAddress.rejected]: (state, action) => {
      state.savedAddressesLoading = false;
      state.addressButtonLoading = false;
      state.savedAddress = [];
      state.savedAddressesErrorMessage = action.payload.message;
      if (action.payload && action.payload.errorCode === "conflicted_entity") {
        state.isConflictedEntity = true;
        const labelNameMatch =
          action.payload.message.match(/labelName:\s*(.*)$/);
        if (labelNameMatch && labelNameMatch[1]) {
          state.conflictedLabelName = labelNameMatch[1];
        }
      } else {
        state.isConflictedEntity = false;
      }
    },
    [updateSavedAddress.pending]: (state) => {
      state.updatedSavedAddressesLoading = true;
      state.addressButtonLoading = true;
    },
    [updateSavedAddress.fulfilled]: (state, action) => {
      state.updatedSavedAddressesLoading = false;
      state.addressButtonLoading = false;
      state.updatedSavedAddressData = action.payload;
    },
    [updateSavedAddress.rejected]: (state, action) => {
      state.updatedSavedAddressesLoading = false;
      state.addressButtonLoading = false;
      state.updatedSavedAddressData = [];
      state.updatedSavedAddressesErrorMessage = action.error.payload;
    },
    [deleteSavedAddress.pending]: (state) => {
      state.deleteSavedAddressesLoading = true;
    },
    [deleteSavedAddress.fulfilled]: (state, action) => {
      state.deleteSavedAddressesLoading = false;
      state.deleteSavedAddressData = action.payload;
    },
    [deleteSavedAddress.rejected]: (state, action) => {
      state.deleteSavedAddressesLoading = false;
      state.deleteSavedAddressData = [];
      state.deleteSavedAddressesErrorMessage = action.error.payload;
    },
  },
});

export const selectSavedAddresses = (state) => {
  return state.savedAddressDetails.allSavedAddresses;
};

export const {
  showGoBackButton,
  removeAllSavedAddress,
  handleAddressConflictModal,
} = savedAddressDetailsSlice.actions;

export default savedAddressDetailsSlice.reducer;
